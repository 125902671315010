import { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    Image,
    FlatList,
    TouchableOpacity,
    ScrollView,
    Pressable,
    ImageBackground,
} from 'react-native';
// import JobGigStyledText from '../../components/JobGigStyledText';
// import { api } from '../../api';
// import ThemedButton from '../../components/ThemedButton';
// import { ApplicantTabsNavigationProps } from '../../navigation/types';
// import { useNavigation } from '@react-navigation/native';
// import { useMutation, useQuery, useQueryClient } from 'react-query';
// import CtlSwitch from '../../components/ControlledInputs/CtlSwitch';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { useUser, useJobs } from '../../hooks';
import { JobMatch, Job, Applicant, UpdateApplicantDto } from '../../types';
import JobPostingModal from '../../components/JobPostingModal';
import { useForm } from 'react-hook-form';
import { getJobTypeText, filterSearchResults } from '../../util/helperFunctions';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { required } from '../../util/formRules';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function ApplicantExploreScreen() {
    // const [errMsg, setErrMsg] = useState('');
    // const { user } = useUser();
    // const navigation = useNavigation<ApplicantTabsNavigationProps>();
    const { control, watch : watchFormValue } = useForm<{ query: string }>();
    const { jobs } = useJobs();
    const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);
    const [showJobPostingModal, setShowJobPostingModal] =
        useState<boolean>(false);
    // Holds the job that the user wants to view in the modal
    const [jobToView, setJobToView] = useState<Job | undefined>();

    useEffect( () => {
        setFilteredJobs(
            filterSearchResults( watchFormValue( 'query' ), jobs!, ['title'] )
        );
    }, [watchFormValue( 'query' ), jobs] );

    const Item = (job: Job) => (
        <Pressable onPress={() => handleOpenJobPostingModal(job)}>
            <View style={styles.jobListItem}>
                <View style={styles.jobItemHeaderWrapper}>
                    <Text style={styles.jobItemTitle}>{job.title}</Text>
                </View>
                <View style={styles.jobItemSubheaderWrapper}>
                    <Text style={styles.jobItemSubtitle}>
                        {job.jobCategory?.name},{' '}
                        {job.highSchoolReq
                            ? 'HS Diploma Required'
                            : 'No HS Diploma Required'}
                    </Text>
                </View>
                <View style={styles.jobItemContent}>
                    <Text style={styles.jobItemData}>
                        {getJobTypeText(job.jobType)}
                    </Text>
                </View>
            </View>
        </Pressable>
    );

    function handleOpenJobPostingModal(job: Job) {
        setJobToView(job);
        setShowJobPostingModal(true);
    }

    function handleCloseJobPostingModal() {
        setShowJobPostingModal(false);
        setJobToView(undefined);
    }

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                {jobs && jobs.length > 0 && (
                    <>
                        <View style={Theme.searchContainer}>
                            <CtlTextInput
                                name="query"
                                control={control}
                                rules={required}
                                placeholder="Search"
                            />
                        </View>
                        <View style={styles.jobListContainer}>
                            {filteredJobs && filteredJobs.length > 0 && (
                                <FlatList
                                    scrollEnabled={false}
                                    data={filteredJobs}
                                    renderItem={({ item }) => <Item {...item} />}
                                    keyExtractor={(item: Job) => item.id}
                                />
                            )}
                            {!filteredJobs || filteredJobs.length <= 0 && <Text>No jobs found.</Text>}
                        </View>
                    </>
                )}
                {!jobs || jobs.length <= 0 && (
                    <>
                        <View style={Theme.headerContainerCenter}>
                            <Text style={Theme.headerTextCenter}>
                                {
                                    "Uh oh! Looks like there aren't any jobs posted right now."
                                }
                            </Text>
                        </View>
                        <View style={styles.infoWrapper}>
                            <Text style={styles.infoText}>
                                But hang tight, we are updating our job postings
                                daily!
                            </Text>
                        </View>
                    </>
                )}
            </View>

            <JobPostingModal
                visible={showJobPostingModal}
                job={jobToView}
                onCloseFunc={handleCloseJobPostingModal}
            />
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    jobListContainer: {
        width: '100%',
    },
    jobListItem: {
        height: 100,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 5,
        textAlign: 'left',
        borderRadius: 4,
        paddingHorizontal: 10,
        paddingVertical: 10,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        backgroundColor: Colors.theme.white,
        ...Theme.listItemShadow,
    },
    jobItemHeaderWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    jobItemSubheaderWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    jobItemTitle: {
        fontSize: 18,
        fontWeight: '700',
    },
    jobItemSubtitle: {
        fontSize: 14,
        fontWeight: '500',
    },
    jobItemStatusText: {
        fontSize: 12,
    },
    jobItemStatusTextOpen: {
        color: Colors.theme.black,
    },
    jobItemStatusTextClosed: {
        color: Colors.theme.lightGray,
    },
    jobItemContent: {
        flexDirection: 'row',
        marginTop: 5,
    },
    jobItemData: {
        marginTop: 5,
        fontSize: 12,
        color: Colors.theme.lightGray,
    },
    infoWrapper: {
        paddingHorizontal: 20,
    },
    infoText: {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'center',
    },
    imageStyle: {
        marginTop: 50,
        width: 350,
        height: 300,
        resizeMode: 'contain',
    },
});
