import { useCallback, useState } from 'react';
import { StyleSheet, View, Text, Alert, Platform } from 'react-native';
import Theme from '../constants/Theme';
import ThemedButton from '../components/ThemedButton';
import CtlTextInput from '../components/ControlledInputs/CtlTextInput';
import CtlSelectInput from '../components/ControlledInputs/CtlSelectInput';
import Colors from '../constants/Colors';
import { CreateAccountArgs } from '../types';
import { useForm } from 'react-hook-form';
import { required } from '../util/formRules';
import unitedStates from '../util/unitedStates';
import { useNavigation } from '@react-navigation/native';
import { api } from '../api';
import { PublicStackNavigationProps } from '../navigation/types';
import { communicationPref } from '../types/User';
import * as WebBrowser from 'expo-web-browser';
import ThemedScrollView from '../components/ThemedScrollView';
import SignInWithAppleButton from '../components/SignInWithAppleButton';
import SignInWithGoogleButton from '../components/SignInWithGoogleButton';
import { useAuth } from '../hooks';
import { heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

export default function CreateAccountScreen() {
    const { control, handleSubmit, getValues, setValue, watch } =
        useForm<CreateAccountArgs>({
            defaultValues: {
                email: '',
                hashPass: '',
                confirmPass: '',
                firstName: '',
                middleName: '',
                lastName: '',
                phone: '',
                address: {
                    addressOne: '',
                    addressTwo: '',
                    city: '',
                    state: '',
                    zipCode: '',
                },
                commPreference: communicationPref.CALL,
            },
        });
    const { appleLogin, promptGoogleLogin } = useAuth();
    const safeArea = useSafeAreaInsets();
    const [errMsg, setErrMsg] = useState('');
    const [formStep, setFormStep] = useState(0);
    const [hidePass, setHidePass] = useState(true);
    const navigation = useNavigation<PublicStackNavigationProps>();
    WebBrowser.maybeCompleteAuthSession(); // This is needed for Google Login https://docs.expo.dev/guides/google-authentication/#add-a-method-to-dismiss-the-web-popup

    const commPrefArr = [
        {
            label: 'Select',
            value: '',
        },
        {
            label: 'Call',
            value: communicationPref.CALL,
        },
        {
            label: 'Text',
            value: communicationPref.TEXT,
        },
    ];

    const submitForm = async (d: CreateAccountArgs) => {
        try {
            setErrMsg('');
            await api.createAccount(d);
            openSuccessAlert();
        } catch (err: any) {
            if (err.message == 'Applicant already exists.') {
                setErrMsg('An account with this email address already exists.');
            } else {
                setErrMsg('Invalid account information.');
            }
        }
    };

    const validateLoginInfo = async () => {
        try {
            setErrMsg('');
            const emailToCheck = getValues('email');
            const passToCheck = getValues('hashPass');
            if (!emailToCheck || !passToCheck)
                throw new Error('Please enter an email and password.');
            if (await api.checkIfExists(emailToCheck)) {
                setErrMsg('An account with this email address already exists.');
            } else if (passToCheck.length < 8) {
                setErrMsg('Password must be at least 8 characters.');
            } else {
                setFormStep(1);
            }
        } catch (err: any) {
            setErrMsg(err);
        }
    };

    const openSuccessAlert = () => {
        if (Platform.OS === 'web') {
            alert(
                'Success! Your account has been created. Please login with your new credentials.',
            );
            navigation.navigate('Login');
        } else {
            Alert.alert(
                'Success!',
                'Your account has been created. Please login with your new credentials.',
                [
                    {
                        text: 'Login',
                        onPress: () => navigation.navigate('Login'),
                    },
                ],
            );
        }
    };

    const submitAppleLogin = async () => {
        try {
            setErrMsg('');
            await appleLogin();
        } catch (err: any) {
            if (err.code === 'ERR_REQUEST_CANCELED') return; // Don't show error if user cancels Apple login
            setErrMsg('Error logging in with Apple ID');
        }
    };

    const submitGoogleLogin = async () => {
        try {
            setErrMsg('');
            await promptGoogleLogin();
        } catch (err: any) {
            setErrMsg('Error logging in with Google');
        }
    };

    const nextStepDisabled = useCallback(() => {
        if (!watch('email') || !watch('hashPass') || !watch('confirmPass')) {
            return true;
        }
        if (watch('hashPass').length < 8) return false;
        if (watch('hashPass') !== watch('confirmPass')) return true;
        return false;
    }, [watch('email'), watch('hashPass'), watch('confirmPass')]);

    return (
        <ThemedScrollView>
            <View style={[Theme.pageContent, { marginTop: 25 }]}>
                {formStep === 0 && (
                    <>
                        <View style={Theme.headerContainer}>
                            <Text style={Theme.headerText}>
                                Let's set up your profile.
                            </Text>
                        </View>
                        <View style={Theme.formContainer}>
                            <CtlTextInput
                                name="email"
                                control={control}
                                rules={required}
                                label="Email Address"
                                placeholder="Email Address"
                                textContentType="emailAddress"
                            />
                            <CtlTextInput
                                name="hashPass"
                                control={control}
                                rules={required}
                                label="Password (8 characters)"
                                textContentType="password"
                                secureTextEntry={hidePass}
                                placeholder="Password"
                            />
                            <CtlTextInput
                                name="confirmPass"
                                control={control}
                                rules={required}
                                label="Confirm Password"
                                textContentType="password"
                                secureTextEntry={hidePass}
                                placeholder="Confirm Password"
                            />
                            <Text
                                style={[
                                    Theme.mutedText,
                                    {
                                        fontSize: 12,
                                        textDecorationLine: 'underline',
                                        marginLeft: 5,
                                        marginBottom: 15,
                                    },
                                ]}
                                onPress={() => setHidePass((prev) => !prev)}
                            >
                                {hidePass ? 'Show' : 'Hide'} Password
                            </Text>
                        </View>
                        {errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}
                        <ThemedButton
                            onPress={validateLoginInfo}
                            title="Next Step"
                            variant="large"
                            color="black"
                            style={styles.nextStepButton}
                            disabled={nextStepDisabled()}
                        />
                        <View
                            style={{
                                ...Theme.passportMethodContainer,
                                marginTop: hp('15%'),
                                marginBottom: safeArea.bottom,
                            }}
                        >
                            <SignInWithAppleButton onPress={submitAppleLogin} />
                            <SignInWithGoogleButton
                                onPress={submitGoogleLogin}
                            />
                        </View>
                    </>
                )}
                {formStep === 1 && (
                    <>
                        <View style={Theme.headerContainer}>
                            <Text style={Theme.headerText}>
                                Profile & Contact Details
                            </Text>
                        </View>
                        <View style={Theme.formContainer}>
                            <View style={styles.firstAndMiddle}>
                                <CtlTextInput
                                    name="firstName"
                                    control={control}
                                    rules={required}
                                    label="First Name"
                                    placeholder="First Name"
                                    textContentType="givenName"
                                    containerStyle={{ width: '60%' }}
                                />
                                <CtlTextInput
                                    name="middleName"
                                    control={control}
                                    label="Middle Initial"
                                    textContentType="middleName"
                                    placeholder="Middle Initial"
                                    containerStyle={{ width: '35%' }}
                                />
                            </View>
                            <CtlTextInput
                                name="lastName"
                                control={control}
                                rules={required}
                                label="Last Name"
                                textContentType="familyName"
                                placeholder="Last Name"
                            />
                            <CtlTextInput
                                name="phone"
                                control={control}
                                rules={required}
                                label="Phone Number"
                                textContentType="telephoneNumber"
                                placeholder="Phone Number"
                            />
                            <CtlTextInput
                                name="address.addressOne"
                                control={control}
                                rules={required}
                                label="Address Line 1"
                                textContentType="streetAddressLine1"
                                placeholder="Address Line 1"
                            />
                            <CtlTextInput
                                name="address.addressTwo"
                                control={control}
                                label="Address Line 2 - optional"
                                textContentType="streetAddressLine2"
                                placeholder="Address Line 2 - optional"
                            />
                            <CtlTextInput
                                name="address.city"
                                control={control}
                                rules={required}
                                label="City"
                                textContentType="addressCity"
                                placeholder="City"
                            />
                            <CtlSelectInput
                                name="address.state"
                                setValue={(val) =>
                                    setValue('address.state', val)
                                }
                                control={control}
                                label="State"
                                items={unitedStates}
                                rules={required}
                            />
                            <CtlTextInput
                                name="address.zipCode"
                                control={control}
                                rules={required}
                                label="Zip Code"
                                textContentType="postalCode"
                                placeholder="Zip Code"
                            />

                            <CtlSelectInput
                                name="commPreference"
                                setValue={(val) =>
                                    setValue(
                                        'commPreference',
                                        val as communicationPref,
                                    )
                                }
                                control={control}
                                label="Preferred Method of Communication"
                                items={commPrefArr}
                                rules={required}
                            />
                        </View>
                        <Text style={Theme.errMsg}>{errMsg}</Text>
                        <ThemedButton
                            onPress={handleSubmit(submitForm)}
                            title="Create Account"
                            variant="large"
                            color="black"
                            style={styles.createAccountButton}
                        />
                    </>
                )}
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    firstAndMiddle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    nextStepButton: {
        marginBottom: 8,
    },
    createAccountButton: {
        marginTop: 14,
        marginBottom: 14,
    },
    subHeader: {
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 30,
    },
    subHeaderLets: {
        color: Colors.theme.black,
    },
    subHeaderWork: {
        color: Colors.theme.orange,
    },
    existingAccountContainer: {
        marginTop: 70,
        marginBottom: 70,
    },
    existingAccountText: {
        fontSize: 16,
    },
    existingAccountTextBold: {
        fontWeight: 'bold',
    },
    signInLink: {
        textDecorationLine: 'underline',
    },
    passportMethods: {
        position: 'absolute',
        bottom: 80,
    },
});
