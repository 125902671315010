import Colors from './Colors';
import { StyleSheet } from 'react-native';
const PAGE_PADDING_HORIZONTAL = 15;
const PAGE_PADDING_VERTICAL = 15;
const ELEVATION = 3; // Necessary for shadows to appear in Android

const Theme = StyleSheet.create({
    page: {
        display: 'flex',
        flex: 1,
        color: Colors.theme.black,
        backgroundColor: Colors.theme.backgroundColor,
        fontFamily: 'Roboto',
    },
    pageContent: {
        width: '100%',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
        justifyContent: 'flex-start',
        paddingVertical: PAGE_PADDING_VERTICAL,
        paddingHorizontal: PAGE_PADDING_HORIZONTAL,
    },
    elevation: {
        elevation: ELEVATION,
    },
    headerText: {
        fontSize: 22,
        fontWeight: '700',
        justifyContent: 'flex-start',
    },
    headerTextCenter: {
        fontSize: 22,
        fontWeight: '700',
        justifyContent: 'center',
        textAlign: 'center',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        width: '100%',
    },
    headerContainerCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 30,
        marginBottom: 30,
        width: '100%',
    },
    subHeader: {
        fontSize: 14,
    },
    searchContainer: {
        marginVertical: 20,
        flexDirection: 'column',
        alignSelf: 'stretch',
        alignItems: 'center',
    },
    listItemStyle: {
        borderRadius: 4,
        backgroundColor: Colors.theme.white,
    },
    listItemShadow: {
        shadowColor: '#203947',
        shadowOffset: { width: 0, height: 1 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        elevation: ELEVATION,
    },
    inputContainer: {
        display: 'flex',
        alignSelf: 'stretch',
        flexDirection: 'column',
        marginBottom: 20,
    },
    input: {
        display: 'flex',
        alignSelf: 'stretch',
        backgroundColor: Colors.theme.white,
        borderRadius: 4,
        padding: 15,
        borderColor: Colors.theme.lightGray,
        borderWidth: 1,
    },
    inputDisabled: {
        display: 'flex',
        alignSelf: 'stretch',
        backgroundColor: Colors.theme.lightGray,
        borderRadius: 4,
        padding: 15,
        borderColor: Colors.theme.lightGray,
        borderWidth: 1,
    },
    inputLabelContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    inputLabel: {
        fontWeight: 'bold',
        marginBottom: 4,
        marginTop: 6,
    },
    errMsg: {
        color: Colors.theme.error,
        marginVertical: 10,
    },
    modalBlurBackground: {
        flex: 1,
        width: '100%',
        height: '90%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        margin: 30,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 20,
        elevation: 5,
        width: '80%',
        maxHeight: '65%',
    },
    modalActionsContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        flexBasis: 'auto',
        justifyContent: 'flex-end',
        paddingBottom: 40,
    },
    modalActionButton: {
        marginTop: 15,
    },
    modalHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    modalHeaderText: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: '600',
    },
    mutedText: {
        fontSize: 14,
        color: Colors.theme.lightGray,
    },
	passportMethodContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        width: '100%',
    },
	passportMethodButton: {
		display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 50,
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        fontStyle: 'bold',
	},
	passportMethodButtonBorder: {
        borderWidth: 0.8,
        borderColor: Colors.theme.black,
    },
    passportMethodLogo: {
        height: 15,
        width: 15,
        resizeMode: 'contain',
    },
    passportMethodText: {
        marginLeft: 7.5,
        fontSize: 15,
        color: Colors.theme.black,
        fontWeight: '500',
        textAlign: 'center',
    },
    linkText: {
        paddingTop: 5,
        fontSize: 16,
        textDecorationLine: 'underline',
        textAlignVertical: 'center',
        textAlign: 'center',
    },
    dangerLinkText: {
        paddingVertical: 10,
        fontSize: 14,
        textDecorationLine: 'underline',
        textAlignVertical: 'center',
        color: Colors.theme.error,
    },
    dataContainer: {
        marginVertical: 10,
    },
    dataLabel: {
        fontSize: 16,
        fontWeight: '600',
        color: Colors.theme.lightGray,
    },
    dataValue: {
        fontSize: 16,
    },
    formContainer: {
        width: '100%',
        paddingLeft: 0,
        textAlign: 'left',
        marginTop: 20,
    },
});

export default Theme;
