import { useState } from 'react';
import { View, Text, ScrollView, Linking } from 'react-native';
import Theme from '../../constants/Theme';
import { api } from '../../api';
import {
    AdminCallLogStackProps,
    AdminCallLogStackScreenProps,
} from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import ThemedButton from '../../components/ThemedButton';
import { CallLog, CallLogStatus, UpdateCallLogDto } from '../../types';
import { useQuery, useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import CtlRadioButton from '../../components/ControlledInputs/CtlRadioButton';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import CtlCheckbox from '../../components/ControlledInputs/CtlCheckbox';
import { queryClient } from '../../providers/query';
import { useUser } from '../../hooks';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminEditCallLogScreen({
    route,
}: AdminCallLogStackScreenProps<'EditCallLog'>) {
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isDirty },
    } = useForm<CallLog & { answered: boolean }>(
        {
            defaultValues: {
                answered: undefined,
                notes: '',
                remindMe: false,
            },
        },
    );
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<AdminCallLogStackProps>();
    const { user } = useUser();

    useQuery(
        ['call-log', route.params.id],
        () => api.getCallLog(route.params.id),
        {
            onSuccess: (data) => {
                reset(data);
            },
            onError: () => {
                setErrMsg('Error fetching call log');
            },
        },
    );

    const submitForm = async (d: CallLog) => {
        if (!isDirty) navigation.goBack();
        try {
            setErrMsg('');
            if (getValues().answered) {
                d.status = CallLogStatus.ANSWERED;
            } else {
                d.status = CallLogStatus.FOLLOW_UP;
            }
            const dto = {} as UpdateCallLogDto;
            Object.assign(dto, d);
            dto.contactedBy = user?.id;
            dto.applicant = getValues().applicant?.id;
            dto.job = getValues().job?.id;
            await editMutation.mutateAsync(dto);
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating call log');
        }
    };

    const editMutation = useMutation(
        (dto: UpdateCallLogDto) => api.updateCallLog(route.params.id, dto),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['call-log']);
            },
        },
    );

	const handleCallClick = (callId: string, phone: string) => {
        if (phone !== null && phone !== undefined && phone !== '') {
            Linking.openURL(`tel:${phone}`);
        }
    };


    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainerCenter}>
                    <Text style={Theme.headerTextCenter}>{`${
                        getValues().applicant?.firstName
                    } ${getValues().applicant?.lastName}`}</Text>
                </View>

				<ThemedButton 
				title='Call Applicant'
				variant='large'
				color='orange'
				onPress={() => handleCallClick(getValues().applicant?.id, getValues().applicant?.phone)}
				style={{marginBottom: 60}}
				/>
                <CtlRadioButton
                    name="answered"
                    control={control}
                    groupLabel="Did they answer?"
                />
                <CtlTextInput
                    name="notes"
                    control={control}
                    label="Call Notes (optional)"
                    placeholder="Enter notes here... (max 250 chars)"
                    multiline
                    maxLength={250}
                />
                <CtlCheckbox
                    name="remindMe"
                    control={control}
                    label="Remind me to follow up in two weeks"
                />
                <Text style={Theme.errMsg}>{errMsg}</Text>
                <ThemedButton
                    title="Log Call"
                    variant="fluid"
                    color="black"
                    onPress={handleSubmit(submitForm)}
                />
            </View>
        </ThemedScrollView>
    );
}
