import React from 'react';
import Svg, { Defs, Image, Rect, Pattern, Use, SvgUri } from 'react-native-svg';
import { CustomSVGIconProps } from '../types';
import LogoBase64 from './LogoBase64';

const Logo = (props: CustomSVGIconProps) => {
    return (
        <Svg
            width={props.width ? props.width : '500'}
            height={props.height ? props.height : '363'}
            viewBox="0 0 500 363"
            fill="none"
        >
            <Rect width="500" height="363" fill="url(#pattern0)" />
            <Defs>
                <Pattern
                    id="pattern0"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <Use href="#image0_1502_467" scale={[0.002, 0.00275482]} />
                </Pattern>
                <Image
                    id="image0_1502_467"
                    width="500"
                    height="363"
                    // react-native-svg doesn't seem to support plain string for this prop...
                    // but intended use "{{uri: 'data:image/png;base64, ...'}}" doesn't compile
                    // correctly to web
                    // this seems to work fine for both web and mobile for now
                    // @ts-ignore
                    xlinkHref={LogoBase64}
                />
            </Defs>
        </Svg>
    );
};

export default Logo;
