import { Platform } from 'react-native';
import * as AppleAuthentication from 'expo-apple-authentication';
import Theme from '../constants/Theme';

type SignInWithAppleButtonProps = {
    onPress: () => void;
};

const SignInWithAppleButton = (props: SignInWithAppleButtonProps) => {
    if (Platform.OS !== 'ios') return null;
    return (
        <AppleAuthentication.AppleAuthenticationButton
            buttonType={
                AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN
            }
            buttonStyle={
                AppleAuthentication.AppleAuthenticationButtonStyle.WHITE_OUTLINE
            }
            cornerRadius={50}
            onPress={props.onPress}
            style={Theme.passportMethodButton}
        />
    );
};

export default SignInWithAppleButton;
