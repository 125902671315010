import { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    Image,
    FlatList,
    TouchableOpacity,
    ScrollView,
    Pressable,
    ImageBackground,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import JobGigStyledText from '../../components/JobGigStyledText';
import { useUser } from '../../hooks';
import { api } from '../../api';
import ThemedButton from '../../components/ThemedButton';
import { ApplicantTabsNavigationProps } from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { JobMatch, Job, Applicant, UpdateApplicantDto } from '../../types';
import JobPostingModal from '../../components/JobPostingModal';
import CtlSwitch from '../../components/ControlledInputs/CtlSwitch';
import { useForm } from 'react-hook-form';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function ApplicantJobMatchesScreen() {
    type AvailableFormArg = Pick<Applicant, 'id' | 'available'>;

    const [errMsg, setErrMsg] = useState('');
    const { user } = useUser();
    const queryClient = useQueryClient();
    const navigation = useNavigation<ApplicantTabsNavigationProps>();
    const [matches, setMatches] = useState<JobMatch[]>([]);
    const [incompleteSections, setIncompleteSections] = useState<string[]>([]);
    const [showJobPostingModal, setShowJobPostingModal] =
        useState<boolean>(false);
    // Holds the job that the user wants to view in the modal
    const [jobToView, setJobToView] = useState<Job | undefined>();
    const [applicant, setApplicant] = useState<Applicant>();
    const {
        control,
        watch: watchValues,
        getValues,
        reset,
    } = useForm<AvailableFormArg>(
        {
            defaultValues: {
                available: false,
            },
        }
    );

    useQuery(
        ['profileCompletion', user?.id],
        async () => {
            if (user) return await api.getProfileCompletion(user.id);
        },
        {
            enabled: !!user,
            retry: 1,
            onSuccess: (data) => {
                if (!data) return;
                setIncompleteSections(data);
            },
            onError: (err: Error) => {
                setErrMsg(err.message);
            },
        },
    );

    useQuery(
        ['applicant', user?.id],
        async () => {
            if (user) return await api.getApplicant(user.id);
        },
        {
            enabled: !!user,
            retry: 1,
            onSuccess: (data) => {
                if (!data) return;
                setApplicant(data);
                reset(data);
            },
            onError: (err: Error) => {
                setErrMsg(err.message);
            },
        },
    );

    useQuery(
        ['job-matches', user?.id],
        async () => {
            if (user) return await api.getJobMatchesByApplicant(user.id);
        },
        {
            enabled: !!user,
            retry: 1,
            onSuccess: (data) => {
                if (data) {
                    setMatches(data);
                }
            },
            onError: (err: Error) => {
                setErrMsg(err.message);
            },
        },
    );

    useEffect(() => {
        if (!getValues()) return;
        setErrMsg('');
        updateMutation.mutateAsync(getValues());
    }, [watchValues('available')]);

    async function updateApplicant(d: UpdateApplicantDto) {
        if (!d.id) return;
        return await api.updateApplicant(d.id, d);
    }

    const updateMutation = useMutation(
        (d: UpdateApplicantDto) => updateApplicant(d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['applicant', user?.id]);
                queryClient.invalidateQueries(['user', user?.id]);
                queryClient.invalidateQueries(['profileCompletion']);
                queryClient.invalidateQueries(['job-matches', user?.id]);
            },
            onError: (err: Error) => {
                setErrMsg('Error updating profile');
            },
        },
    );

    const Item = (match: JobMatch) => (
        <Pressable onPress={() => handleOpenJobPostingModal(match.job)}>
            <View style={styles.jobListItem}>
                <View style={styles.jobItemHeaderWrapper}>
                    <Text style={styles.jobItemTitle}>{match.job.title}</Text>
                </View>
                <View style={styles.jobItemSubheaderWrapper}>
                    <Text style={styles.jobItemSubtitle}>
                        {match.job.jobCategory?.name},{' '}
                        {match.job.highSchoolReq
                            ? 'HS Diploma Required'
                            : 'No HS Diploma Required'}
                    </Text>
                </View>
                <View style={styles.jobItemContent}>
                    <Text style={styles.jobItemData}>
                        ${match.job.payRate}
                        {match.job.payFlexible ? ', Flexible' : ', Fixed'}
                    </Text>
                </View>
            </View>
        </Pressable>
    );

    function handleOpenJobPostingModal(job: Job) {
        setJobToView(job);
        setShowJobPostingModal(true);
    }

    function handleCloseJobPostingModal() {
        setShowJobPostingModal(false);
        setJobToView(undefined);
    }

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                {/*	Profile is complete and available and has matches */}
                {applicant?.available &&
                    matches.length > 0 &&
                    incompleteSections.length === 0 && (
                        <>
                            <View style={styles.jobListContainer}>
                                <FlatList
                                    scrollEnabled={false}
                                    data={matches}
                                    renderItem={({ item }) => (
                                        <Item {...item} />
                                    )}
                                    keyExtractor={(item: JobMatch) => item.id}
                                />
                            </View>
                            <View style={styles.infoWrapper}>
                                <Text style={styles.infoText}>
                                    A recruiter will be contacting you about
                                    these matches shortly. Make sure your
                                    contact information is up to date.
                                </Text>
                                <ThemedButton
                                    style={{ marginVertical: 20 }}
                                    title="Update Contact Info"
                                    color="black"
                                    variant="small"
                                    onPress={() => {
                                        navigation.navigate('ProfileStack');
                                    }}
                                />
                            </View>
                        </>
                    )}
                {/*	Profile is complete and available but no matches */}
                {applicant?.available &&
                    matches.length === 0 &&
                    incompleteSections.length === 0 && (
                        <>
                            <View style={Theme.headerContainerCenter}>
                                <Text style={Theme.headerTextCenter}>
                                    Searching for Your Match
                                </Text>
                            </View>
                            <View style={styles.infoWrapper}>
                                <Text style={styles.infoText}>
                                    In the meantime, check out the job
                                    categories we are currently hiring for.
                                </Text>
                            </View>
                            <Image
                                style={styles.imageStyle}
                                source={require('../../assets/onboard-match-search.png')}
                            />
                            <ThemedButton
                                style={{ marginVertical: 50 }}
                                title="Browse Jobs"
                                color="black"
                                variant="small"
                                onPress={() => {
                                    navigation.navigate('Explore');
                                }}
                            />
                        </>
                    )}
                {/*	Profile complete but not available for work*/}
                {applicant &&
                    !applicant.available &&
                    incompleteSections.length === 0 && (
                        <>
                            <View style={Theme.headerContainerCenter}>
                                <Text style={Theme.headerTextCenter}>
                                    Update Your Status
                                </Text>
                            </View>
                            <View style={styles.infoWrapper}>
                                <Text style={styles.infoText}>
                                    If you would like to be matched, change your
                                    status to "Available for work".
                                </Text>
                            </View>
                            <View style={{ alignItems: 'center' }}>
                                <CtlSwitch
                                    name="available"
                                    control={control}
                                    label="Available for work"
                                />
                            </View>
                        </>
                    )}
                {/*	Profile is not complete*/}
                {incompleteSections.length > 0 && (
                    <>
                        <View style={Theme.headerContainerCenter}>
                            <Text style={Theme.headerTextCenter}>
                                Complete Your Profile
                            </Text>
                        </View>
                        <View style={styles.infoWrapper}>
                            <Text style={styles.infoText}>
                                Once your profile is complete and you have
                                changed your status to "available" you will be
                                eligible for matches.
                            </Text>
                        </View>
                        <Image
                            style={styles.imageStyle}
                            source={require('../../assets/onboard-profile.png')}
                        />
                        <ThemedButton
                            style={{ marginVertical: 50 }}
                            title="Complete Profile"
                            color="black"
                            variant="small"
                            onPress={() => {
                                navigation.navigate('ProfileStack');
                            }}
                        />
                    </>
                )}
                {errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}
            </View>

            <JobPostingModal
                visible={showJobPostingModal}
                job={jobToView}
                onCloseFunc={handleCloseJobPostingModal}
            />
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    jobListContainer: {
        width: '100%',
    },
    jobListItem: {
        height: 100,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 5,
        textAlign: 'left',
        borderRadius: 4,
        paddingHorizontal: 10,
        paddingVertical: 10,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        backgroundColor: Colors.theme.white,
        ...Theme.listItemShadow,
    },
    jobItemHeaderWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    jobItemSubheaderWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    jobItemTitle: {
        fontSize: 18,
        fontWeight: '700',
    },
    jobItemSubtitle: {
        fontSize: 14,
        fontWeight: '500',
    },
    jobItemStatusText: {
        fontSize: 12,
    },
    jobItemStatusTextOpen: {
        color: Colors.theme.black,
    },
    jobItemStatusTextClosed: {
        color: Colors.theme.lightGray,
    },
    jobItemContent: {
        flexDirection: 'row',
        marginTop: 5,
    },
    jobItemData: {
        marginTop: 5,
        fontSize: 12,
        color: Colors.theme.lightGray,
    },
    infoWrapper: {
        paddingHorizontal: 20,
    },
    infoText: {
        fontSize: 14,
        fontWeight: '500',
        textAlign: 'center',
    },
    imageStyle: {
        marginTop: 50,
        width: 350,
        height: 300,
        resizeMode: 'contain',
    },
});
