import {
    FieldValues,
    FieldPath,
    FieldError,
    UseFormSetValue,
    UseFormGetValues,
Controller,
} from 'react-hook-form';
import { ControlledInputProps } from '../../types/ControlledInput';
import { useState } from 'react';
import {
    en,
    registerTranslation,
    DatePickerModal,
} from 'react-native-paper-dates';
import { View, Pressable, Text, ViewStyle } from 'react-native';
import { format as formatDate, parseISO, isDate } from 'date-fns';
import Theme from '../../constants/Theme';
registerTranslation('en', en);
export interface DatePickerInputProps<K> {
    label?: string;
    error?: FieldError | undefined;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    style?: ViewStyle | undefined;
    noEnd?: boolean;
}

export default function CtlDatePickerInput<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    control,
    rules,
    ...rest
}: ControlledInputProps<TFieldValues, TName> &
    DatePickerInputProps<TFieldValues>) {
    const { label, startDate, endDate, style, noEnd } = rest;
    const [open, setOpen] = useState(false);

    const onDismissSingle = () => {
        setOpen(false);
    };

    const getInputText = ( value: any ) => {
        if ( !value ) return 'Select Date';
        const parsedDate : Date = isDate( value ) ? value : parseISO( value );
        return formatDate(parsedDate, 'MM/dd/yyyy');
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={
                ( {
                    field: { value, onChange },
                    fieldState: { error },
                } ) => (
                    <View style={[Theme.inputContainer, style]}>
                        <Text style={Theme.inputLabel}>{label}</Text>
                        <Pressable
                            disabled={noEnd ? noEnd : false}
                            onPress={(e) => {
                                if (noEnd) return;
                                e.preventDefault();
                                setOpen(true);
                            }}
                            style={noEnd ? Theme.inputDisabled : Theme.input}
                        >
                            <Text>{getInputText( value )}</Text>
                        </Pressable>
                        <Text style={Theme.errMsg}>{error?.message}</Text>
                        <DatePickerModal
                            locale="en"
                            mode="single"
                            visible={open}
                            onDismiss={onDismissSingle}
                            date={typeof value == 'string' ? parseISO( value ) : value}
                            onConfirm={(val) => {
                                onChange( val.date )
                                setOpen(false);
                            }}
                            validRange={{
                                startDate: startDate,
                                endDate: endDate,
                            }}
                        />
                    </View>
                )
            }
        />
    );
}
