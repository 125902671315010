export const required = {
    required: { value: true, message: 'This field is required.' },
};

export const isEmail = {
    pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
    },
};

export const atLeastOne = {
    required: {
        value: false,
        message: 'Select a response',
    },
};

export const requiredNumber = {
    required: {
        value: true,
        message: 'This field is required. Please enter a valid entry!',
    },
    pattern: {
        value: /^\d+$/,
        message: 'This field must be a number.',
    },
};

export const requiredZip = {
    required: { value: true, message: 'This field is required.' },
    // this pattern fits examples below
    // 12345
    // 12345-6789
    pattern: {
        value: /^\d{5}(-\d{4})?$/,
        message: 'Invalid zip code',
    },
};

export const requiredPhone = {
    required: { value: true, message: 'This field is required.' },
    // this pattern fits examples below
    // 1234567890
    // 123-456-7890
    // 123.456.7890
    // 123 456 7890
    // (123) 456 7890
    // (123).456.7890
    // (123)-456-7890
    // (123)456-7890
    // (123)4567890
    pattern: {
        value: /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
        message: 'Invalid phone number',
    },
};

export const requiredEmail = {
    required: { value: true, message: 'This field is required.' },
    pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: 'Invalid email address',
    },
};
