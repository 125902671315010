import { useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    FlatList,
    TouchableOpacity,
    Linking,
    ScrollView,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { useUser } from '../../hooks';
import { api } from '../../api';
import { Ionicons } from '@expo/vector-icons';
import { useAuth } from '../../hooks';
import {
    AdminDashboardStackProps,
    AdminDashboardStackScreenProps,
    ApplicantProfileStackProps,
    ApplicantTabsNavigationProps,
    ProtectedStackNavigationProps,
} from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import ProfilePicture from '../../components/ProfilePicture';
import { useQuery, useQueryClient } from 'react-query';
import { Applicant } from '../../types';
import { CommonActions } from '@react-navigation/native';
import ThemedButton from '../../components/ThemedButton';
import ThemedScrollView from '../../components/ThemedScrollView';

type ProfileListItem = {
    key: string;
    title: string;
    linkTo: string;
    incomplete: boolean;
    inactive?: boolean;
};
export default function AdminViewApplicantScreen({
    route,
}: AdminDashboardStackScreenProps<'ViewApplicant'>) {
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<AdminDashboardStackProps>();
    const [incompleteSections, setIncompleteSections] = useState<string[]>([]);
    const [applicantToView, setApplicantToView] = useState<
        Applicant | undefined
    >(undefined);

    const authService = useAuth();
    useQuery(
        'profileCompletion',
        async () => {
            if (!route.params.id) return;
            return await api.getProfileCompletion(route.params.id);
        },
        {
            retry: 1,
            onSuccess: (data) => {
                if (data) setIncompleteSections(data);
            },
            onError: (err: Error) => {
                setErrMsg('Error loading profile completion.');
            },
        },
    );

    useQuery(
        ['applicant', route.params.id],
        async () => {
            if (!route.params.id) return;
            return await api.getApplicant(route.params.id);
        },
        {
            retry: 1,
            onSuccess: (data) => {
                if (data) setApplicantToView(data);
            },
            onError: (err: Error) => {
                setErrMsg('Error loading applicant.');
            },
        },
    );

    //To change where a list item navs to, update both the linkTo and the switch statement
    const items: Array<ProfileListItem> = [
        {
            key: '1',
            title: 'Profile & Contact Details',
            linkTo: 'Contact',
            incomplete: incompleteSections.includes('contact'),
        },
        {
            key: '2',
            title: 'Eligibility',
            linkTo: 'Eligibility',
            incomplete: incompleteSections.includes('eligibility'),
        },
        {
            key: '3',
            title: 'Education',
            linkTo: 'Education',
            incomplete: incompleteSections.includes('education'),
        },
        {
            key: '4',
            title: 'Job History',
            linkTo: 'JobHistory',
            incomplete: incompleteSections.includes('jobHistory'),
        },
        {
            key: '5',
            title: 'Job Preferences',
            linkTo: 'JobPreferences',
            incomplete: incompleteSections.includes('jobPreferences'),
        },
        {
            key: '6',
            title: 'Confirmation of Accuracy',
            linkTo: 'AccuracyConfirmation',
            incomplete: incompleteSections.includes('confirmation'),
            inactive:
                incompleteSections.filter(
                    (section) => section != 'confirmation',
                ).length > 0,
            //Only make this item active if all other sections are complete
        },
    ];

    const profileScreenNavigation = (linkTo: string) => {
        if (!route.params.id) return;
        switch (linkTo) {
            case 'Contact':
                navigation.navigate('ViewApplicantContact', {
                    id: route.params.id,
                });
                break;
            case 'Eligibility':
                navigation.navigate('ViewApplicantEligibility', {
                    id: route.params.id,
                });
                break;
            case 'Education':
                navigation.navigate('ViewApplicantEducation', {
                    id: route.params.id,
                });
                break;
            case 'JobHistory':
                navigation.navigate('ViewApplicantJobHistory', {
                    id: route.params.id,
                });
                break;
            case 'JobPreferences':
                navigation.navigate('ViewApplicantJobPreferences', {
                    id: route.params.id,
                });
                break;
            case 'AccuracyConfirmation':
                navigation.navigate('ViewApplicantAccuracy', {
                    id: route.params.id,
                });
                break;
            default:
                break;
        }
    };

    const Item = ({
        title,
        linkTo,
        incomplete,
        inactive,
    }: {
        title: string;
        linkTo: string;
        incomplete: boolean;
        inactive?: boolean;
    }) => (
        <TouchableOpacity
            onPress={() => {
                if (!inactive) profileScreenNavigation(linkTo);
            }}
        >
            <View style={styles.profileListItem}>
                <View style={styles.profileListItemLeft}>
                    <Text
                        style={
                            inactive
                                ? styles.profileListItemTitleInactive
                                : styles.profileListItemTitle
                        }
                    >
                        {title}
                    </Text>
                    {incomplete && (
                        <View style={styles.profileListItemFlag}>
                            <Text style={styles.profileListItemFlagText}>
                                Incomplete
                            </Text>
                        </View>
                    )}
                </View>
                <View style={styles.profileListItemRight}>
                    <Ionicons
                        name="chevron-forward"
                        size={20}
                        color={inactive ? Colors.theme.lightGray : 'black'}
                    />
                </View>
            </View>
        </TouchableOpacity>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={styles.headerContainer}>
                    {errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}
                    <ProfilePicture
                        initials={`${applicantToView?.firstName?.charAt(
                            0,
                        )}${applicantToView?.lastName?.charAt(0)}`}
                    />
                    <Text style={styles.username}>
                        {applicantToView?.firstName} {applicantToView?.lastName}
                    </Text>
                    <ThemedButton
                        title="Call Applicant"
                        color="black"
                        style={styles.callButton}
                        onPress={() => {
                            if (!applicantToView?.phone) return;
                            Linking.openURL(
                                `tel:${applicantToView?.phone}`,
                            );
                        }}
                    />
                </View>
                <View style={styles.profileListContainer}>
                    <FlatList
                        scrollEnabled={false}
                        data={items}
                        renderItem={({ item }) => (
                            <Item
                                title={item.title}
                                linkTo={item.linkTo}
                                incomplete={item.incomplete}
                                inactive={item.inactive}
                            />
                        )}
                        keyExtractor={(item: ProfileListItem) => item.key}
                    />
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    callButton: {
        marginTop: 10,
    },
    headerContainer: {
        alignItems: 'center',
    },
    username: {
        fontSize: 22,
        fontWeight: '500',
    },
    profileListContainer: {
        width: '100%',
        marginVertical: 20,
    },
    profileListItem: {
        height: 50,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 4,
        textAlign: 'left',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        paddingLeft: 15,
        ...Theme.listItemStyle,
        ...Theme.listItemShadow,
    },
    profileListItemLeft: {
        flexBasis: '90%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
    },
    profileListItemRight: {
        flexBasis: '10%',
        justifyContent: 'center',
        paddingHorizontal: 5,
    },
    profileListItemTitle: {
        fontSize: 18,
        fontWeight: '500',
    },
    profileListItemTitleInactive: {
        fontSize: 18,
        fontWeight: '500',
        color: Colors.theme.lightGray,
    },
    profileListItemFlag: {
        marginLeft: 5,
        backgroundColor: 'rgba(219, 59, 50, 0.2)',
        width: 55,
        height: 15,
        borderRadius: 4,
    },
    profileListItemFlagText: {
        fontSize: 9,
        color: Colors.theme.orange,
        alignSelf: 'center',
        textAlignVertical: 'center',
    },
    fillOutProfileButton: {
        marginTop: 75,
    },
    logOutLink: {
        fontSize: 16,
        textDecorationLine: 'underline',
        textAlign: 'center',
    },
});
