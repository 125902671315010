import { useEffect, useState } from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import Theme from '../../../constants/Theme';
import ThemedButton from '../../../components/ThemedButton';
import CtlTextInput from '../../../components/ControlledInputs/CtlTextInput';
import CtlSelectInput from '../../../components/ControlledInputs/CtlSelectInput';
import Colors from '../../../constants/Colors';
import { UpdateApplicantDto } from '../../../types';
import { useForm } from 'react-hook-form';
import { required, requiredPhone, requiredZip } from '../../../util/formRules';
import unitedStates from '../../../util/unitedStates';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../../api';
import { ApplicantProfileStackProps } from '../../../navigation/types';
import { communicationPref } from '../../../types/User';
import { useQueryClient, useMutation } from 'react-query';
import { useUser } from '../../../hooks';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function ContactScreen() {
    const viewOnly = true;
    type ContactFormArgs = UpdateApplicantDto;
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        formState: { isDirty },
    } = useForm<ContactFormArgs>({
        defaultValues: {
            firstName: '',
            middleName: '',
            lastName: '',
            phone: '',
            address: {
                addressOne: '',
                addressTwo: '',
                city: '',
                state: '',
                zipCode: '',
            },
            commPreference: communicationPref.CALL,
        },
    });
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<ApplicantProfileStackProps>();
    const queryClient = useQueryClient();
    const { user } = useUser();
    const commPrefArr = [
        {
            label: 'Select',
            value: '',
        },
        {
            label: 'Call',
            value: communicationPref.CALL,
        },
        {
            label: 'Text',
            value: communicationPref.TEXT,
        },
    ];

    const editMutation = useMutation(
        (d: UpdateApplicantDto) => api.updateApplicant(d.id, d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user', user?.id]);
                queryClient.invalidateQueries(['applicant', user?.id]);
                queryClient.invalidateQueries('profileCompletion');
            },
        },
    );

    useEffect(() => {
        async function fetchUser() {
            if (!user) return;
            await api
                .getApplicant(user.id)
                .then((res) => {
                    reset(res);
                })
                .catch((err) => {
                    setErrMsg(err);
                });
        }
        fetchUser();
    }, []);

    const submitForm = async (d: UpdateApplicantDto) => {
        try {
            setErrMsg('');
            await editMutation.mutateAsync(d);
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>
                        Profile & Contact Details
                    </Text>
                </View>
                <View style={Theme.formContainer}>
                    <View style={styles.firstAndMiddle}>
                        <CtlTextInput
                            name="firstName"
                            control={control}
                            rules={required}
                            label="First Name"
                            placeholder="First Name"
                            textContentType="givenName"
                            containerStyle={{ width: '60%' }}
                            editable={!viewOnly}
                        />
                        <CtlTextInput
                            name="middleName"
                            control={control}
                            label="Middle"
                            textContentType="middleName"
                            placeholder="Initial"
                            containerStyle={{ width: '35%' }}
                        />
                    </View>
                    <CtlTextInput
                        name="lastName"
                        control={control}
                        rules={required}
                        label="Last Name"
                        textContentType="familyName"
                        placeholder="Last Name"
                    />
                    <CtlTextInput
                        name="phone"
                        control={control}
                        rules={requiredPhone}
                        label="Phone Number"
                        textContentType="telephoneNumber"
                        placeholder="Phone Number"
                    />
                    <CtlTextInput
                        name="address.addressOne"
                        control={control}
                        rules={required}
                        label="Address Line 1"
                        textContentType="streetAddressLine1"
                        placeholder="Address Line 1"
                    />
                    <CtlTextInput
                        name="address.addressTwo"
                        control={control}
                        label="Address Line 2 - optional"
                        textContentType="streetAddressLine2"
                        placeholder="Address Line 2 - optional"
                    />
                    <CtlTextInput
                        name="address.city"
                        control={control}
                        rules={required}
                        label="City"
                        textContentType="addressCity"
                        placeholder="City"
                    />
                    <CtlSelectInput
                        name="address.state"
                        control={control}
                        label="State"
                        items={unitedStates}
                        rules={required}
                    />
                    <CtlTextInput
                        name="address.zipCode"
                        control={control}
                        rules={requiredZip}
                        label="Zip Code"
                        textContentType="postalCode"
                        placeholder="Zip Code"
                    />

                    <CtlSelectInput
                        name="commPreference"
                        control={control}
                        setValue={(val) =>
                            setValue('commPreference', val as communicationPref)
                        }
                        label="Preferred Method of Communication"
                        items={commPrefArr}
                        rules={required}
                    />
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
                <ThemedButton
                    onPress={handleSubmit(submitForm)}
                    title="Save Profile"
                    variant="fluid"
                    color="black"
                    style={styles.saveProfileButton}
                    disabled={!isDirty}
                />
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    firstAndMiddle: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    nextStepButton: {
        marginTop: 8,
        marginBottom: 8,
    },
    saveProfileButton: {
        marginTop: 14,
        marginBottom: 30,
    },
    subHeader: {
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 30,
    },
});
