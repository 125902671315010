import { useState, useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import Theme from '../../constants/Theme';
import ThemedButton from '../../components/ThemedButton';
import Colors from '../../constants/Colors';
import { CreateJobDto, JobType } from '../../types';
import { useForm } from 'react-hook-form';
import { required, requiredNumber } from '../../util/formRules';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../api';
import {
    AdminDashboardStackScreenProps,
    AdminTabsNavigationProps,
} from '../../navigation/types';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useUser } from '../../hooks';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import CtlSelectInput from '../../components/ControlledInputs/CtlSelectInput';
import CtlCheckbox from '../../components/ControlledInputs/CtlCheckbox';
import CtlDatePickerInput from '../../components/ControlledInputs/CtlDatePickerInput';
import { Client, JobCategory, UpdateJobDto } from '../../types';
import JobAddressModal from '../../components/JobAddressModal';
import ThemedCheckbox from '../../components/ThemedCheckbox';
import ThemedScrollView from '../../components/ThemedScrollView';
import DeleteEntityModal from '../../components/DeleteEntityModal';

export default function AdminEditJobScreen({
    route,
}: AdminDashboardStackScreenProps<'EditJob'>) {
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        watch: watchValues,
        setValue,
        formState: { isDirty },
    } = useForm<UpdateJobDto>({
        defaultValues: {
            title: '',
            description: '',
            payRate: '16.00',
            payFlexible: false,
            startDate: undefined,
            endDate: undefined,
            deadline: undefined,
            highSchoolReq: false,
            notFelon: false,
            referencesReq: false,
            jobType: [],
            client: undefined,
            jobCategory: undefined,
            address: {
                addressOne: '',
                addressTwo: '',
                city: '',
                state: '',
                zipCode: '',
            },
        },
    });
    const [mode, setMode] = useState<'create' | 'edit'>('create');
    const [errMsg, setErrMsg] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigation = useNavigation<AdminTabsNavigationProps>();
    const queryClient = useQueryClient();
    const { user } = useUser();
    const [clients, setClients] = useState<Client[]>([]);
    const [jobCategories, setJobCategories] = useState<JobCategory[]>([]);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showAddressError, setShowAddressError] = useState(false);

    useEffect(() => {
        if (route.params.id) {
            setMode('edit');
            loadExistingJob();
            return;
        }
        setMode('create');
    }, []);

    async function loadExistingJob() {
        try {
            if (!route.params.id) return;
            const res = await api.getJob(route.params.id);
            if (!res) throw new Error();
            reset(res);
        } catch (err) {
            setErrMsg('Error loading job');
            return;
        }
    }

    useQuery(['clients', user?.id], () => api.getClients(), {
        onSuccess: (data: Client[]) => {
            if (data[0].id !== '')
                data.unshift({
                    id: '',
                    name: 'Select a client',
                    address: {
                        addressOne: '',
                        addressTwo: '',
                        city: '',
                        state: '',
                        zipCode: '',
                    },
                });
            setClients(data);
        },
        onError: () => {
            setErrMsg('Error loading clients');
        },
    });

    useQuery(['jobCategories', user?.id], () => api.getJobCategories(), {
        onSuccess: (data: JobCategory[]) => {
            if (data[0].id !== '')
                data.unshift({
                    id: '',
                    name: 'Select a category',
                });
            setJobCategories(data);
        },
        onError: () => {
            setErrMsg('Error loading job categories');
        },
    });

    const createMutation = useMutation(
        (data: CreateJobDto) => api.createJob(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs']);
                navigation.navigate('JobPostings');
            },
            onError: () => {
                setErrMsg('Error creating job');
            },
        },
    );

    const editMutation = useMutation(
        (d: UpdateJobDto) => {
            return api.updateJob(route.params.id ?? '', d);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['jobs', route.params.id]);
                queryClient.invalidateQueries(['jobs']);
                navigation.navigate('JobPostings');
            },
            onError: () => {
                setErrMsg('Error updating job.');
            },
        },
    );

    const deleteMutation = useMutation(
        () => api.disableJob(route.params.id ?? ''),
        {
            onSuccess: () => {
                setShowDeleteModal(false);
                queryClient.invalidateQueries(['jobs', route.params.id]);
                queryClient.invalidateQueries(['jobs']);
                navigation.navigate('JobPostings');
            },
            onError: () => {
                setShowDeleteModal(false);
                setErrMsg('Error deleting job.');
            },
        },
    );

    function addressValid(): boolean {
        if (
            !getValues('address') ||
            !getValues('address.addressOne') ||
            !getValues('address.city') ||
            !getValues('address.state') ||
            !getValues('address.zipCode')
        ) {
            return false;
        }
        return true;
    }

    const submitForm = async (d: UpdateJobDto) => {
        if (!addressValid()) {
            setShowAddressError(true);
            return;
        }
        if (!isDirty) {
            navigation.goBack();
        }
        try {
            console.log(d);
            setErrMsg('');
            if (mode === 'create') {
                await createMutation.mutateAsync(d);
                reset();
                navigation.goBack();
                return;
            }
            await editMutation.mutateAsync(d);
            reset();
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    function handleJobTypeChange(value: boolean, typeKey: JobType) {
        let currentJobType = getValues('jobType');
        if (!currentJobType) currentJobType = [];
        if (value && !currentJobType.includes(typeKey)) {
            setValue('jobType', [...currentJobType, typeKey]);
        } else if (!value && currentJobType.includes(typeKey)) {
            setValue(
                'jobType',
                currentJobType.filter((j) => j !== typeKey),
            );
            return;
        } else {
            return;
        }
    }

    return (
        <ThemedScrollView>
            <DeleteEntityModal
                show={showDeleteModal}
                entityType="Job"
                onConfirm={deleteMutation.mutateAsync}
                onCancel={() => setShowDeleteModal(false)}
            />
            <JobAddressModal
                show={showAddressModal}
                control={control}
                onClose={() => setShowAddressModal(false)}
            />
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>
                        {mode === 'edit' ? 'Edit' : 'Create'} Job
                    </Text>
                </View>
                <View style={Theme.formContainer}>
                    <CtlTextInput
                        name="title"
                        label="Job Title"
                        placeholder="Job Title"
                        control={control}
                        rules={required}
                    />
                    <CtlSelectInput
                        name="client.id"
                        label="Client"
                        control={control}
                        setValue={(val) => setValue('client.id', val)}
                        rules={required}
                        items={clients.map((c) => {
                            return { label: c.name, value: c.id };
                        })}
                    />
                    <View style={{ marginVertical: 10 }}>
                        <Text style={{ marginBottom: 5, fontWeight: 'bold' }}>
                            Job Address{' '}
                        </Text>
                        <ThemedButton
                            title="Edit"
                            color="black"
                            onPress={() => setShowAddressModal(true)}
                        />
                        {showAddressError && (
                            <Text style={{ color: 'red' }}>
                                This field is required
                            </Text>
                        )}
                    </View>
                    <CtlDatePickerInput
                        label="Start Date"
                        name="startDate"
                        control={control}
                        rules={required}
                        startDate={new Date()}
                    />
                    <CtlDatePickerInput
                        label="End Date (optional)"
                        name="endDate"
                        control={control}
                        noEnd={!!!watchValues('startDate')}
                        startDate={
                            getValues('startDate')
                                ? getValues('startDate')
                                : new Date()
                        }
                    />
                    <CtlDatePickerInput
                        label="Deadline to Apply"
                        name="deadline"
                        control={control}
                        startDate={new Date()}
                        noEnd={!!!watchValues('startDate')}
                        endDate={
                            getValues('startDate')
                                ? getValues('startDate')
                                : undefined
                        }
                    />
                    <CtlTextInput
                        label="Hourly Pay Rate ($0.00)"
                        name="payRate"
                        placeholder="Hourly Pay Rate ($0.00)"
                        control={control}
                        rules={requiredNumber}
                    />
                    <CtlCheckbox
                        name="payFlexible"
                        control={control}
                        label="Pay is flexible"
                        style={styles.inputMargin}
                    />
                    <CtlTextInput
                        label="Job Description"
                        name="description"
                        placeholder="Job Description"
                        control={control}
                        multiline={true}
                        maxLength={250}
                    />
                    <CtlSelectInput
                        name="jobCategory.id"
                        label="Job Category"
                        items={jobCategories.map((c) => {
                            return { label: c.name, value: c.id };
                        })}
                        setValue={(val) => setValue('jobCategory.id', val)}
                        control={control}
                        rules={required}
                    />
                    <View>
                        <Text
                            style={StyleSheet.flatten([
                                Theme.inputLabel,
                                { marginBottom: 10 },
                            ])}
                        >
                            Job Type
                        </Text>
                        <ThemedCheckbox
                            label="Full Time"
                            onValueChange={(val) =>
                                handleJobTypeChange(val, JobType.FullTime)
                            }
                            value={watchValues('jobType')?.includes(
                                JobType.FullTime,
                            )}
                        />
                        <ThemedCheckbox
                            label="Part Time"
                            onValueChange={(val) =>
                                handleJobTypeChange(val, JobType.PartTime)
                            }
                            value={watchValues('jobType')?.includes(
                                JobType.PartTime,
                            )}
                        />
                        <ThemedCheckbox
                            label="Seasonal"
                            onValueChange={(val) =>
                                handleJobTypeChange(val, JobType.Seasonal)
                            }
                            value={watchValues('jobType')?.includes(
                                JobType.Seasonal,
                            )}
                        />
                    </View>
                    <View>
                        <Text
                            style={StyleSheet.flatten([
                                Theme.inputLabel,
                                { marginBottom: 10 },
                            ])}
                        >
                            Minimum Qualifications
                        </Text>
                        <CtlCheckbox
                            name="highSchoolReq"
                            control={control}
                            label="Must have a high school or high school equivalency diploma"
                        />
                        <CtlCheckbox
                            name="notFelon"
                            control={control}
                            label="Cannot have been convicted of a felony"
                        />
                        <CtlCheckbox
                            name="referencesReq"
                            control={control}
                            label="Must be willing to provide three references"
                        />
                    </View>
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
                {mode === 'edit' && (
                    <Text
                        style={Theme.dangerLinkText}
                        onPress={() => setShowDeleteModal(true)}
                    >
                        Delete Job
                    </Text>
                )}

                <ThemedButton
                    title={mode === 'edit' ? 'Save Job' : 'Create Job'}
                    onPress={handleSubmit(submitForm)}
                    variant="fluid"
                    color="black"
                    style={styles.saveEligibilityButton}
                />
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    inputMargin: {
        marginBottom: 20,
    },
    modalHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    multiColumnContainer: {
        flexDirection: 'row',
        overflow: 'hidden',
        justifyContent: 'space-between',
    },
    eligibilityNotice: {
        fontSize: 16,
        fontWeight: '600',
        marginTop: 20,
        textAlign: 'left',
    },
    nextStepButton: {
        marginTop: 8,
        marginBottom: 8,
    },
    saveEligibilityButton: {
        marginTop: 14,
        marginBottom: 20,
    },
    subHeader: {
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 30,
    },
    switchLabel: {
        marginLeft: 5,
        fontSize: 16,
        color: Colors.theme.lightGray,
    },
    workAuthContainer: {
        marginVertical: 20,
    },
});
