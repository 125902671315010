import { useEffect, useState } from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import Theme from '../../constants/Theme';
import ThemedButton from '../../components/ThemedButton';
import Colors from '../../constants/Colors';
import { Admin, UpdateApplicantDto } from '../../types';
import { useForm } from 'react-hook-form';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../api';
import {
    ProtectedStackNavigationProps,
    PublicStackNavigationProps,
} from '../../navigation/types';
import { useQueryClient, useMutation } from 'react-query';
import { useAuth, useUser } from '../../hooks';
import CtlCheckbox from '../../components/ControlledInputs/CtlCheckbox';
import { Applicant, BaseUser, UserRole } from '../../types/User';
import ThemedScrollView from '../../components/ThemedScrollView';
import DeleteEntityModal from '../../components/DeleteEntityModal';
import { isAdminType } from '../../util/helperFunctions';

export default function UserSettingsScreen() {
    type AdminSettingsArgs = Pick<
        Admin,
        'id' | 'adminNotifSettings' | 'notifSettings'
    >;
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isDirty },
    } = useForm<Admin | Applicant>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<ProtectedStackNavigationProps>();
    const publicNavigation = useNavigation<PublicStackNavigationProps>();
    const queryClient = useQueryClient();
    const { user } = useUser();
    const authService = useAuth();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [resetSent, setResetSent] = useState(false);

    const editAdminMutation = useMutation(
        (d: Admin) => api.updateAdmin(d.id, d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user', user?.id]);
            },
        },
    );

    useEffect(() => {
        fetchUser();
    }, []);

    async function fetchUser() {
        try {
            if (!user) return;
            if ([UserRole.ADMIN, UserRole.SUPERADMIN].includes(user.role)) {
                const adminRes = await api.getAdmin(user.id);

                if (!adminRes) throw new Error('badres');
                reset(adminRes);
                return;
            }

            const applicantRes = await api.getApplicant(user.id);
            if (!applicantRes) throw new Error('badres');

            reset(applicantRes);
        } catch (err) {
            setErrMsg('Error fetching user data.');
        }
    }

    const submitForm = async (d: Admin | Applicant) => {
        if (!isDirty) {
            navigation.goBack();
            return;
        }
        try {
            setErrMsg('');
            if (
                user &&
                user.role &&
                [UserRole.ADMIN, UserRole.SUPERADMIN].includes(user.role) &&
                isAdminType(d)
            ) {
                await editAdminMutation.mutateAsync(d);
            }
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    async function handleResetPassword() {
        try {
            const res = await api.requestPasswordReset(getValues('email'));

            if (res !== 'success') {
                throw new Error('badres');
            }
            setResetSent(true);
        } catch (err) {
            setErrMsg('Error requesting password reset.');
        }
    }

    async function handleDeleteUser() {
        try {
            setShowDeleteModal(false);
            if (!user) return;
            const res = await api.disableUser(user.id);
            if (res !== 'success') throw new Error('badres');
            authService.logout();
            publicNavigation.navigate('Login');
        } catch (err) {
            setErrMsg('Error deleting user.');
        }
    }

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Settings</Text>
                </View>
                {errMsg !== '' && <Text style={Theme.errMsg}>{errMsg}</Text>}
                <View style={styles.formContainer}>
                    {user && !user.federatedId ? (
                        <View>
                            <Text style={styles.formLabel}>
                                Change Password
                            </Text>
                            <Text style={styles.formSubLabel}>
                                An email will be sent to{' '}
                                <Text style={{ fontWeight: 'bold' }}>
                                    {user?.email}
                                </Text>{' '}
                                with reset instructions.
                            </Text>
                            <ThemedButton
                                title="Send Reset Email"
                                variant="small"
                                color="black"
                                style={styles.resetPassBtn}
                                onPress={() => handleResetPassword()}
                            />
                            {resetSent && (
                                <Text
                                    style={{
                                        color: Colors.theme.lightBlue,
                                        marginTop: 10,
                                    }}
                                >
                                    Password reset email sent. Please check your
                                    inbox. If you do not see the email, please
                                    check your spam folder.
                                </Text>
                            )}
                        </View>
                    ) : (
                        <>
                            <Text style={styles.formLabel}>
                                Change Password
                            </Text>
                            <Text style={styles.formSubLabel}>
                                You are using an exteral login provider. Please
                                visit your account settings with that provider
                                to change your password.
                            </Text>
                        </>
                    )}
                </View>
                {user &&
                    user.role &&
                    [UserRole.ADMIN, UserRole.SUPERADMIN].includes(
                        user.role,
                    ) && (
                        <>
                            <View style={styles.formContainer}>
                                <Text style={styles.formLabel}>
                                    Notification Settings
                                </Text>
                                <Text style={styles.formSubLabel}>
                                    Notify me when:
                                </Text>
                                <View style={styles.checkBoxesContainer}>
                                    <CtlCheckbox
                                        name="notifSettings.newJobMatch"
                                        control={control}
                                        label="A new applicant matches to a job"
                                    />
                                    <CtlCheckbox
                                        name="adminNotifSettings.jobListExpired"
                                        control={control}
                                        label="A job listing is expired"
                                    />
                                    <CtlCheckbox
                                        name="adminNotifSettings.followUpNeeded"
                                        control={control}
                                        label="It's time to follow up with an applicant"
                                    />
                                    <CtlCheckbox
                                        name="adminNotifSettings.newProfileCompleted"
                                        control={control}
                                        label="A new applicant completes their profile"
                                    />
                                </View>
                            </View>
                            <View style={{ justifyContent: 'center' }}>
                                <ThemedButton
                                    onPress={handleSubmit(submitForm)}
                                    title="Save"
                                    variant="large"
                                    color="black"
                                />
                            </View>
                        </>
                    )}
                {user &&
                    user.role &&
                    [UserRole.ADMIN, UserRole.APPLICANT].includes(
                        user.role,
                    ) && (
                        <View style={styles.formContainer}>
                            <View>
                                <Text style={styles.formLabel}>
                                    Delete Account
                                </Text>
                                <Text style={styles.formSubLabel}>
                                    Caution: This action cannot be undone!
                                </Text>
                                <ThemedButton
                                    title="Delete My Account"
                                    variant="small"
                                    color="orange"
                                    style={styles.resetPassBtn}
                                    onPress={() => setShowDeleteModal(true)}
                                />
                            </View>
                        </View>
                    )}
            </View>
            <DeleteEntityModal
                show={showDeleteModal}
                entityType="account"
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={() => handleDeleteUser()}
            />
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    formContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginVertical: 10,
    },
    formLabel: {
        fontSize: 18,
        marginBottom: 4,
        fontWeight: 'bold',
    },
    formSubLabel: {
        fontSize: 14,
        fontWeight: '400',
        color: Colors.theme.lightGray,
    },
    formInput: {
        marginBottom: 20,
    },
    checkBoxesContainer: {
        paddingLeft: 10,
        marginTop: 10,
    },
    resetPassBtn: {
        marginTop: 20,
        maxWidth: '50%',
    },
});
