import {
	useController,
	FieldValues,
	FieldPath,
	FieldError,
	Controller,
} from 'react-hook-form';
import { useEffect, useState } from 'react';
import { ControlledInputProps } from '../../types/ControlledInput';
import { View, StyleSheet, Text, ViewStyle, TouchableOpacity } from 'react-native';
import { MultiSelect } from 'react-native-element-dropdown';
import AntDesign from '@expo/vector-icons/AntDesign';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';

export type ThemedMultiSelectInputProps = {
	label?: string;
	error?: FieldError | undefined;
	containerStyle?: ViewStyle;
	items: Record<string, any>; //* item value left as any to allow for multiple types
};

/**
* A controlled Multiselect input component that uses react-hook-form on top of
* an imported multiselect input component from react-native-element-dropdown.
* @param label (optional) - The label to display above the select input
* @param error (optional) - The error message to display below the select input
* @param containerStyle (optional) - The style to apply to the container
* @param items - The items to display in the select input
*/
export default function CtlSelectInput<
	TFieldValues extends FieldValues = FieldValues,
	TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
	name,
	control,
	rules,
	...rest
}: ControlledInputProps<TFieldValues, TName> & ThemedMultiSelectInputProps) {
	const { containerStyle, items, label } = rest;

	const renderItem = (item: any) => {
			return (
					<View style={styles.item}>
							<Text style={styles.selectedTextStyle}>{item.label}</Text>
							<AntDesign name="Safety" size={20} color="black" style={styles.icon} />
					</View>
			)
	};

	return (
			<Controller
					control={control}
					name={name}
					rules={rules}
					render={({
							field: { value, onChange, onBlur },
							fieldState: { error },
					}) => (
							<View
									style={StyleSheet.flatten([
											containerStyle,
											styles.defaultContainerStyle,
									])}
							>
									<MultiSelect
											style={styles.picker}
											onBlur={onBlur}
											placeholderStyle={styles.placeholderStyle}
											selectedTextStyle={styles.selectedTextStyle}
											inputSearchStyle={styles.inputSearchStyle}
											iconStyle={styles.iconStyle}
											data={items as any[]}
											labelField='label'
											valueField='value'
											placeholder={label}
											value={((): string[] => {
												if ( !value[0] || !value[0].id ) return value;
												return value.map( (v:any) => v.id )
											})()}
											search
											searchPlaceholder='Search...'
											onChange={onChange}
											renderLeftIcon={() => (
													<AntDesign style={styles.icon} name="Safety" size={20} color="black" />
											)}
											renderItem={renderItem}
											renderSelectedItem={( item, unSelect ) => (
													<TouchableOpacity onPress={() => {
														if ( !unSelect ) return;
														unSelect(item)
													}}>
															<View style={styles.selectedStyle}>
																	<Text style={styles.selectedTextStyle}>{item.label}</Text>
																	<Ionicons name="close" size={10} color="black" style={styles.countyPillIcon} />
															</View>
													</TouchableOpacity>
											)}
									/>
									<Text style={styles.errMsg}>{error && error?.message}</Text>
							</View>
					)}
			/>
	);
}

const styles = StyleSheet.create({
	defaultContainerStyle: {
			display: 'flex',
			alignSelf: 'stretch',
	},
	labelContainer: {
			flexDirection: 'row',
			marginTop: 10,
	},
	label: {
			fontWeight: 'bold',
			marginBottom: 4,
	},
	picker: {
			backgroundColor: Colors.theme.backgroundColor,
			borderRadius: 5,
			borderColor: Colors.theme.lightGray,
			borderWidth: 1,
			height: 'auto',
	},
	errMsg: {
			color: Colors.theme.error,
	},
	placeholderStyle: {
			fontSize: 16,
	},
	selectedTextStyle: {
		fontSize: 14,
	},
	iconStyle: {
		width: 20,
		height: 20,
	},
	inputSearchStyle: {
		height: 40,
		fontSize: 16,
	},
	icon: {
		marginRight: 5,
	},
	item: {
		padding: 17,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	selectedStyle: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 14,
		backgroundColor: 'white',
		shadowColor: '#000',
		marginTop: 8,
		marginRight: 12,
		paddingHorizontal: 12,
		paddingVertical: 8,
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.2,
		shadowRadius: 1.41,
		elevation: 2,
	},
	textSelectedStyle: {
		marginRight: 5,
		fontSize: 16,
	},
	countyPillIcon: {
			marginTop: 2,
			marginLeft: 2,
	},
});
