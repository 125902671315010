import { ScrollView, View } from 'react-native';
import {
    heightPercentageToDP as hp,
    widthPercentageToDP as wp,
} from 'react-native-responsive-screen';
import Theme from '../constants/Theme';
const ThemedScrollView = (props: React.PropsWithChildren) => {
    return (
        <View
            style={{
                display: 'flex',
                flex: 1,
                height: hp('100%'), // This is needed for the ScrollView to scroll (height must be bounded)
            }}
        >
            <ScrollView style={Theme.page}>{props.children}</ScrollView>
        </View>
    );
};

export default ThemedScrollView;
