import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { authService } from '../util/authService';
import { UnauthenticatedError } from '../util/errors';
import Constants from 'expo-constants';
const baseURL =
    Constants.expoConfig?.extra?.REACT_APP_BASE_URL ||
    'http://localhost:3001/api';

export const _axios = axios.create({
    baseURL,
});

export class AxiosInstance {
    _setAuthHeader(config: AxiosRequestConfig, token: string) {
        if (!config.headers) {
            config.headers = {};
        }
        config.headers = {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        };
    }

    async _req<R>(config: AxiosRequestConfig) {
        try {
            const res = await _axios.request<R>(config);
            return res;
        } catch (_err) {
            const err = _err as AxiosError;
            if (err.response && err.response.status === 401) {
                // Request successful, but the server responded with a status code
                // outside 2xx
                return err.response;
            }
            throw err;
        }
    }
    async request<R>(
        config: AxiosRequestConfig,
        options?: { noRetry?: boolean },
    ) {
        const token = await authService.getAccessToken();
        if (token) {
            this._setAuthHeader(config, token);
        }
        let res = await this._req<R>(config);
        if (res.status === 401 && !options?.noRetry) {
            const newAccessToken = await this.getNewAccessToken();
            this._setAuthHeader(config, newAccessToken);
            res = await _axios.request<R>(config);
        }

        return res as AxiosResponse<R>;
    }

    async getNewAccessToken() {
        const refreshToken = await authService.getRefreshToken();
        if (!refreshToken) {
            // if no access token is returned. clear credentials and navigation should automatically redirect to login
            await authService.clearCredentials();
            throw new UnauthenticatedError();
        }
        const res = await axios.request({
            method: 'post',
            url: `${baseURL}/token`,
            data: {
                refreshToken,
            },
        });
        if (!res.data.accessToken) {
            // if no access token is returned. clear credentials and navigation should automatically redirect to login
            await authService.clearCredentials();
            throw new UnauthenticatedError();
        }

        await authService.setAccessToken(res.data.accessToken);
        return res.data.accessToken;
    }
}

export const axiosInstance = new AxiosInstance();
