import {
    FieldValues,
    FieldPath,
    FieldError,
    Controller,
} from 'react-hook-form';
import {
    TextInput,
    TextInputProps as DefaultTextInputProps,
    ViewStyle,
    StyleSheet,
    View,
    Text,
} from 'react-native';
import { ControlledInputProps } from '../../types/ControlledInput';
import Colors from '../../constants/Colors';
import Theme from '../../constants/Theme';

export interface TextInputProps extends DefaultTextInputProps {
    name?: string;
    label?: string;
    optional?: boolean;
    containerStyle?: ViewStyle;
    centerText?: boolean;
    error?: FieldError | undefined;
}

/**
 * A controlled text input component that uses react-hook-form on top of
 * a custom ThemedTextInput component.
 */
export default function CtlTextInput<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    control,
    rules,
    ...rest
}: ControlledInputProps<TFieldValues, TName> & TextInputProps) {
    const {
        value = '',
        label,
        optional,
        containerStyle,
        centerText,
        ...textInputProps
    } = rest;

    const textAlign: {
        justifyContent: 'center' | 'flex-start';
        textAlign: 'center' | 'left';
        paddingLeft?: number;
    } = {
        justifyContent: centerText ? 'center' : 'flex-start',
        textAlign: centerText ? 'center' : 'left',
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
            }) => (
                <View
                    style={StyleSheet.flatten([
                        Theme.inputContainer,
                        containerStyle,
                    ])}
                >
                    {label && (
                        <View
                            style={StyleSheet.flatten([
                                Theme.inputLabelContainer,
                                textAlign,
                            ])}
                        >
                            <Text style={Theme.inputLabel}>{label}</Text>
                            {optional && (
                                <Text style={inputStyles.optionalText}>
                                    Optional
                                </Text>
                            )}
                        </View>
                    )}
                    <TextInput
                        onBlur={onBlur}
                        onChangeText={onChange}
                        value={value}
                        style={StyleSheet.flatten([Theme.input, textAlign])}
                        placeholderTextColor="rgba(43, 48, 52, 0.3)"
                        autoCorrect={false} // This may be causing crashes on Android - disable for now to be safe
                        {...textInputProps}
                    />
                    {error && (
                        <Text style={Theme.errMsg} {...rest}>
                            {error && error.message}
                        </Text>
                    )}
                </View>
            )}
        />
    );
}

const inputStyles = StyleSheet.create({
    optionalText: {
        marginLeft: 8,
        fontStyle: 'italic',
        color: Colors.theme.orange,
    },
});
