import AsyncStorage from '@react-native-async-storage/async-storage';
import { SuccessfulLoginResponse } from '../types';
import { User } from '../types/User';

const USER_KEY = 'user';
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const FIRST_VISIT_KEY = 'firstVisit';

class AuthService {
    async storeCredentials(creds: SuccessfulLoginResponse) {
        await this.clearCredentials();
        await AsyncStorage.multiSet([
            [USER_KEY, JSON.stringify(creds.user)],
            [ACCESS_TOKEN_KEY, creds.accessToken],
        ]);
        if (creds.refreshToken) {
            await AsyncStorage.setItem(REFRESH_TOKEN_KEY, creds.refreshToken);
        }
    }

    async clearCredentials() {
        await AsyncStorage.multiRemove([
            USER_KEY,
            ACCESS_TOKEN_KEY,
            REFRESH_TOKEN_KEY,
        ]);
    }

    async getUser(): Promise<User | null> {
        const usr = await AsyncStorage.getItem(USER_KEY);
        if (!usr) return null;
        return JSON.parse(usr) as User;
    }

    async setUser(user: User) {
        await AsyncStorage.setItem(USER_KEY, JSON.stringify(user));
    }

    async getAccessToken() {
        try {
            return await AsyncStorage.getItem(ACCESS_TOKEN_KEY);
        } catch (err) {
            console.error('Error fetching access token:', err);
            return null;
        }
    }

    async setAccessToken(tok: string) {
        return AsyncStorage.setItem(ACCESS_TOKEN_KEY, tok);
    }

    async getRefreshToken() {
        try {
            return await AsyncStorage.getItem(REFRESH_TOKEN_KEY);
        } catch (err) {
            console.error('Error fetching refresh token:', err);
            return null;
        }
    }

    async getIsFirstVisit(): Promise<boolean> {
        try {
            const item = await AsyncStorage.getItem(FIRST_VISIT_KEY);
            if (item === null || item === 'true') return true;
            return false;
        } catch (err) {
            console.error('Error fetching first visit:', err);
            return true;
        }
    }

    async setFirstVisitDone() {
        return AsyncStorage.setItem(FIRST_VISIT_KEY, 'false');
    }
}

export const authService = new AuthService();
