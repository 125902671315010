import { Address } from './Address';
import { Client } from './Client';
import { JobCategory } from './JobCategory';

export enum JobType {
    FullTime = 'Full Time',
    PartTime = 'Part Time',
    Seasonal = 'Seasonal',
}

export type Job = {
    id: string;
    title: string;
    description?: string;
    client?: Client;
    jobCategory?: JobCategory;
    address: Address;
    startDate?: Date;
    endDate?: Date;
    deadline?: Date;
    payRate?: string;
    payFlexible?: boolean;
    highSchoolReq?: boolean;
    notFelon?: boolean;
    referencesReq?: boolean;
    jobType: JobType[];
};

export type CreateJobDto = Partial<Job>;
export type UpdateJobDto = Omit<Job, 'id'>;
