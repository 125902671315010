import { useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    FlatList,
    TouchableOpacity,
    ScrollView,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { useUser } from '../../hooks';
import { api } from '../../api';
import { Ionicons } from '@expo/vector-icons';
import { useAuth } from '../../hooks';
import { ApplicantProfileStackProps } from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import ProfilePicture from '../../components/ProfilePicture';
import { useQuery, useQueryClient } from 'react-query';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function ProfileScreen() {
    const [errMsg, setErrMsg] = useState('');
    const [incompleteSections, setIncompleteSections] = useState<string[]>([]);
    const { user } = useUser();
    const authService = useAuth();
    useQuery(
        'profileCompletion',
        async () => {
            if (user) return await api.getProfileCompletion(user.id);
        },
        {
            enabled: !!user,
            retry: 1,
            onSuccess: (data) => {
                if (data) setIncompleteSections(data);
            },
            onError: (err: Error) => {
                setErrMsg(err.message);
            },
        },
    );
    const navigation = useNavigation<ApplicantProfileStackProps>();
    type ProfileListItem = {
        key: string;
        title: string;
        linkTo: string;
        incomplete: boolean;
        inactive?: boolean;
    };

    //To change where a list item navs to, update both the linkTo and the switch statement
    const items: Array<ProfileListItem> = [
        {
            key: '1',
            title: 'Profile & Contact Details',
            linkTo: 'Contact',
            incomplete: incompleteSections.includes('contact'),
        },
        {
            key: '2',
            title: 'Eligibility',
            linkTo: 'Eligibility',
            incomplete: incompleteSections.includes('eligibility'),
        },
        {
            key: '3',
            title: 'Education',
            linkTo: 'Education',
            incomplete: incompleteSections.includes('education'),
        },
        {
            key: '4',
            title: 'Job History',
            linkTo: 'JobHistory',
            incomplete: false,
        },
        {
            key: '5',
            title: 'Job Preferences',
            linkTo: 'JobPreferences',
            incomplete: incompleteSections.includes('jobPreferences'),
        },
        {
            key: '6',
            title: 'Confirmation of Accuracy',
            linkTo: 'AccuracyConfirmation',
            incomplete: incompleteSections.includes('confirmation'),
            inactive:
                incompleteSections.filter(
                    (section) => section != 'confirmation',
                ).length > 0,
            //Only make this item active if all other sections are complete
        },
        {
            key: '7',
            title: 'Settings',
            linkTo: 'Settings',
            incomplete: false,
        },
    ];

    const profileScreenNavigation = (linkTo: string) => {
        switch (linkTo) {
            case 'Contact':
                navigation.navigate('Contact');
                break;
            case 'Eligibility':
                navigation.navigate('Eligibility');
                break;
            case 'Education':
                navigation.navigate('Education');
                break;
            case 'JobHistory':
                navigation.navigate('JobHistory');
                break;
            case 'JobPreferences':
                navigation.navigate('JobPreferences');
                break;
            case 'AccuracyConfirmation':
                navigation.navigate('AccuracyConfirmation');
                break;
            case 'Settings':
                navigation.navigate('Settings');
                break;
            default:
                break;
        }
    };

    const Item = ({
        title,
        linkTo,
        incomplete,
        inactive,
    }: {
        title: string;
        linkTo: string;
        incomplete: boolean;
        inactive?: boolean;
    }) => (
        <TouchableOpacity
            onPress={() => {
                if (!inactive) profileScreenNavigation(linkTo);
            }}
        >
            <View style={styles.profileListItem}>
                <View style={styles.profileListItemLeft}>
                    <Text
                        style={
                            inactive
                                ? styles.profileListItemTitleInactive
                                : styles.profileListItemTitle
                        }
                    >
                        {title}
                    </Text>
                    {incomplete && (
                        <View style={styles.profileListItemFlag}>
                            <Text style={styles.profileListItemFlagText}>
                                Incomplete
                            </Text>
                        </View>
                    )}
                </View>
                <View style={styles.profileListItemRight}>
                    <Ionicons
                        name="chevron-forward"
                        size={20}
                        color={inactive ? Colors.theme.lightGray : 'black'}
                    />
                </View>
            </View>
        </TouchableOpacity>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={styles.headerContainer}>
                    <ProfilePicture
                        initials={`${user?.firstName?.charAt(
                            0,
                        )}${user?.lastName?.charAt(0)}`}
                    />
                    <Text style={styles.username}>
                        {user?.firstName} {user?.lastName}
                    </Text>
                </View>
                <View style={styles.profileListContainer}>
                    <FlatList
                        scrollEnabled={false}
                        data={items}
                        renderItem={({ item }) => (
                            <Item
                                title={item.title}
                                linkTo={item.linkTo}
                                incomplete={item.incomplete}
                                inactive={item.inactive}
                            />
                        )}
                        keyExtractor={(item: ProfileListItem) => item.key}
                    />
                    <Text style={Theme.errMsg}>{errMsg}</Text>
                    <Text
                        style={Theme.linkText}
                        onPress={() => authService.logout()}
                    >
                        Log Out
                    </Text>
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    headerContainer: {
        alignItems: 'center',
    },
    username: {
        fontSize: 22,
        fontWeight: '500',
    },
    profileListContainer: {
        width: '100%',
        marginVertical: 20,
    },
    profileListItem: {
        height: 50,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 4,
        textAlign: 'left',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        paddingLeft: 15,
        ...Theme.listItemStyle,
        ...Theme.listItemShadow,
    },
    profileListItemLeft: {
        flexBasis: '90%',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
    },
    profileListItemRight: {
        flexBasis: '10%',
        justifyContent: 'center',
        paddingHorizontal: 5,
    },
    profileListItemTitle: {
        fontSize: 18,
        fontWeight: '500',
    },
    profileListItemTitleInactive: {
        fontSize: 18,
        fontWeight: '500',
        color: Colors.theme.lightGray,
    },
    profileListItemFlag: {
        marginLeft: 5,
        backgroundColor: 'rgba(219, 59, 50, 0.2)',
        width: 55,
        height: 15,
        borderRadius: 4,
    },
    profileListItemFlagText: {
        fontSize: 9,
        color: Colors.theme.orange,
        alignSelf: 'center',
        textAlignVertical: 'center',
    },
});
