import { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    Text,
    TouchableOpacity,
    FlatList,
    ScrollView,
} from 'react-native';
import Theme from '../../../constants/Theme';
import ThemedButton from '../../../components/ThemedButton';
import Colors from '../../../constants/Colors';
import { useForm } from 'react-hook-form';
import { required } from '../../../util/formRules';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../../api';
import { ApplicantProfileStackProps } from '../../../navigation/types';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useUser } from '../../../hooks';
import CtlDatePickerInput from '../../../components/ControlledInputs/CtlDatePickerInput';
import CtlTextInput from '../../../components/ControlledInputs/CtlTextInput';
import { Ionicons } from '@expo/vector-icons';
import { CreateJobHistoryDto, JobHistory } from '../../../types/Applicant';
import { format } from 'date-fns';
import ThemedScrollView from '../../../components/ThemedScrollView';
import Checkbox from 'expo-checkbox';

export default function JobHistoryScreen() {
    type JobHistoryFormArgs = CreateJobHistoryDto;
    const {
        control,
		getValues,
        setValue,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm<JobHistoryFormArgs>(
        {
            defaultValues: {
                title: '',
                company: '',
                description: '',
                dateStarted: undefined,
                dateEnded: undefined,
            },
        },
    );
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<ApplicantProfileStackProps>();
    const queryClient = useQueryClient();
    const { user } = useUser();
    const [jobHistory, setJobHistory] = useState<JobHistory[]>();
    const [noEnd, setNoEnd] = useState(false);
    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    useQuery('jobHistory', fetchJobHistory);

    const showToast = ( message : string ) => {
        setToastMessage( message );
        setToastVisible( true );
        setTimeout(() => setToastVisible(false), 20000);
    };

    const defaultJobHistory: Partial<JobHistoryFormArgs> = {
        title: '',
        company: '',
        description: '',
    };

    async function fetchJobHistory() {
        if (!user) return;
        await api
            .getJobHistory(user.id)
            .then((res) => {
                setJobHistory(res);
            })
            .catch((e) => {
                setErrMsg(e.message);
            });
    }

    const editMutation = useMutation(
        ({ id, d }: { id: string; d: CreateJobHistoryDto }) =>
            api.createJobHistory(id, d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('jobHistory');
                queryClient.invalidateQueries(['applicant', user?.id]);
                reset(defaultJobHistory);
                setNoEnd(false);
                showToast('Job history record added.');
            },
        },
    );

    const deleteMutation = useMutation(
        ({
            applicantId,
            jobHistoryId,
        }: {
            applicantId: string;
            jobHistoryId: string;
        }) => api.deleteJobHistoryRecord(applicantId, jobHistoryId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('jobHistory');
                queryClient.invalidateQueries('profileCompletion');
                showToast('Job history record deleted.');
            },
        },
    );

    const submitForm = async (d: CreateJobHistoryDto) => {
        if (!isDirty) {
            return;
        }
        try {
            setErrMsg('');
            if (user) await editMutation.mutateAsync({ id: user.id, d: d });
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    const deleteRecord = async (jobHistoryId: string) => {
        if (!user) return;
        try {
            setErrMsg('');
            await deleteMutation.mutateAsync({
                applicantId: user.id,
                jobHistoryId: jobHistoryId,
            });
        } catch (err) {
            setErrMsg('Error deleting record.');
        }
    };

    const Item = ({ props }: { props: JobHistory }) => (
        <View style={styles.historyListItem}>
            <View style={styles.historyListItemLeft}>
                <Text style={styles.historyListItemTitle}>{props.title}</Text>
                <Text style={styles.historyListItemSubtitle}>
                    {props.company}
                </Text>
                <View style={styles.historyListItemDateContainer}>
                    <Text style={styles.historyListItemText}>
                        {format(new Date(props.dateStarted), 'MM/dd/yyyy')}
                    </Text>
                    <Text style={styles.historyListItemText}>
                        {props.dateEnded &&
                            `- ${format(
                                new Date(props.dateEnded),
                                'MM/dd/yyyy',
                            )}`}
                    </Text>
                </View>
            </View>
            <View style={styles.historyListItemRight}>
                <TouchableOpacity onPress={() => deleteRecord(props.id)}>
                    <Ionicons
                        name="trash"
                        size={24}
                        color={Colors.theme.orange}
                    />
                </TouchableOpacity>
            </View>
        </View>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Job History</Text>
                </View>
                <View style={styles.formContainer}>
                    <CtlTextInput
                        name="title"
                        control={control}
                        rules={required}
                        label="Job Title"
                        placeholder="Job Title"
                    />

                    <CtlTextInput
                        name="company"
                        control={control}
                        rules={required}
                        label="Company Name"
                        placeholder="Company Name"
                    />

                    <CtlDatePickerInput
                        name="dateStarted"
                        control={control}
                        rules={required}
                        label="Date Started"
                        endDate={new Date()}
                    />
                    <View style={styles.switchContainer}>
                        <Checkbox
                            style={styles.switch}
                            value={noEnd}
                            onValueChange={( v ) => setNoEnd( v )}
                        />
                        <Text style={styles.switchLabel}>I currently work here</Text>
                    </View>

                    <CtlDatePickerInput
                        name="dateEnded"
                        control={control}
                        label="Date Ended"
                        startDate={getValues('dateStarted') ? new Date(getValues('dateStarted')) : undefined}
                        endDate={new Date()}
                        noEnd={noEnd || !(!!getValues('dateStarted'))}
                    />

                    <CtlTextInput
                        name="description"
                        control={control}
                        rules={required}
                        label="Job Description"
                        placeholder="Key Roles & Responsibilities (max length 250 characters)"
                        multiline={true}
                        maxLength={250}
                    />
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
                <ThemedButton
                    onPress={handleSubmit(submitForm)}
                    title="Save this Job"
                    variant="large"
                    color="black"
                    style={styles.saveEligibilityButton}
                />
                {toastVisible && (
                    <View style={styles.toast}>
                        <Text style={styles.toastText}>{toastMessage}</Text>
                    </View>
                )}
                <View style={styles.jobHistoryContainer}>
                    <FlatList
                        scrollEnabled={false}
                        data={jobHistory}
                        renderItem={({ item }) => <Item props={item} />}
                        keyExtractor={(item: JobHistory) => item.id}
                    />
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    formContainer: {
        width: '100%',
        paddingLeft: 0,
    },
    formLabel: {
        fontSize: 32,
        marginBottom: 20,
        fontWeight: '500',
    },
    formInput: {
        marginBottom: 20,
    },
    eligibilityNotice: {
        fontSize: 16,
        fontWeight: '600',
        marginTop: 20,
        textAlign: 'left',
    },
    nextStepButton: {
        marginTop: 8,
        marginBottom: 8,
    },
    saveEligibilityButton: {
        marginTop: 14,
        marginBottom: 20,
    },
    subHeader: {
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 30,
    },
    switchLabel: {
        marginLeft: 5,
        fontSize: 16,
        color: Colors.theme.black,
    },
    workAuthContainer: {
        marginVertical: 20,
    },
    addJobContainer: {
        marginTop: 20,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textAlignVertical: 'middle',
    },
    addJobLink: {
        textDecorationLine: 'underline',
        fontSize: 16,
    },
    jobHistoryContainer: {
        width: '100%',
    },
    historyListItem: {
        height: 80,
        shadowColor: '#203947',
        shadowOffset: { width: 0, height: 1 },
        shadowRadius: 6,
        shadowOpacity: 0.2,
        borderRadius: 4,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 5,
        textAlign: 'left',
        paddingLeft: 20,
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.theme.white,
    },
    historyListItemLeft: {
        flexBasis: '90%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    historyListItemRight: {
        flexBasis: '10%',
        justifyContent: 'center',
    },
    historyListItemTitle: {
        fontSize: 16,
        fontWeight: '600',
    },
    historyListItemSubtitle: {
        fontSize: 14,
    },
    historyListItemDateContainer: {
        flexDirection: 'row',
    },
    historyListItemText: {
        fontSize: 12,
        color: Colors.theme.lightGray,
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    switch: {
        marginRight: 5,
    },
    toast: {
        position: 'absolute',
        bottom: 0,
        backgroundColor: Colors.theme.green,
        width: '100%',
        padding: 10,
    },
    toastText: {
        color: Colors.theme.white,
    },
});
