import { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    FlatList,
    Modal,
    ScrollView,
    ImageBackground,
    Pressable,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { api } from '../../api';
import { useForm } from 'react-hook-form';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { required } from '../../util/formRules';
import ThemedButton from '../../components/ThemedButton';
import {
    CreateJobCategoryDto,
    JobCategory,
    UpdateJobCategoryDto,
    Job,
} from '../../types';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import PencilIcon from '../../assets/PencilIcon';
import ThemedModal from '../../components/ThemedModal';
import DeleteEntityModal from '../../components/DeleteEntityModal';
import EditJobCategoryModal from '../../components/EditJobCategoryModal';
import ThemedScrollView from '../../components/ThemedScrollView';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { useJobs } from '../../hooks';

export default function AdminManageJobCategoriesScreen() {
    const [errMsg, setErrMsg] = useState('');
    const [jobCategories, setJobCategories] = useState<JobCategory[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { jobs } = useJobs();
    const [categoryToDelete, setCategoryToDelete] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [categoryToEdit, setCategoryToEdit] = useState('');
    const queryClient = useQueryClient();
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isDirty },
    } = useForm<CreateJobCategoryDto>(
        {
            defaultValues: {
                name: '',
            },
        },
    );

    const {
        control: editControl,
        handleSubmit: editHandleSubmit,
        reset: editReset,
        getValues: editGetValues,
        setValue: editSetValue,
        formState: { isDirty: editIsDirty },
    } = useForm<UpdateJobCategoryDto>();

    // TODO: move this querry to a hook
    useQuery('jobCategories', async () => {
        await api
            .getJobCategories()
            .then((res) => {
                setJobCategories(res);
            })
            .catch((err) => {
                setErrMsg(err);
            });
    });

    const submitCreateForm = async (d: CreateJobCategoryDto) => {
        if (isDirty) {
            try {
                setErrMsg('');
                await createMutation.mutateAsync(d);
                reset();
            } catch (err) {
                setErrMsg('Error creating category');
            }
        }
    };

    const createMutation = useMutation(
        (d: CreateJobCategoryDto) => api.createJobCategory(d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('jobCategories');
            },
            onError: () => {
                setErrMsg('Error creating category');
            },
        },
    );

    const deleteMutation = useMutation(
        () => api.disableJobCategory(categoryToDelete),
        {
            onSuccess: () => {
                setShowDeleteModal(false);
                setCategoryToDelete('');
                queryClient.invalidateQueries('jobCategories');
            },
            onError: () => {
                setErrMsg('Error deleting category');
            },
        },
    );

    const editMutation = useMutation(
        (d: UpdateJobCategoryDto) => api.updateJobCategory(categoryToEdit, d),
        {
            onSuccess: () => {
                setShowEditModal(false);
                setCategoryToEdit('');
                queryClient.invalidateQueries('jobCategories');
            },
            onError: () => {
                setErrMsg('Error updating category');
            },
        },
    );

    const handleEditCategory = (category: JobCategory) => {
        setCategoryToEdit(category.id);
        editSetValue('name', category.name);
    };

    const submitEditForm = async () => {
        if (editIsDirty) {
            try {
                setErrMsg('');
                await editMutation.mutateAsync({ name: editGetValues('name') });
            } catch (err) {
                setErrMsg('Error updating category');
            }
        }
    };

    const Item = ({ id, name }: { id: string; name: string }) => {
        const activeJobs = jobs?.filter( ( j ) => {
            return j.jobCategory?.name === name &&
            isAfter( j.deadline!, new Date() );
        }).length;
        const closedJobs = jobs?.filter( ( n ) => {
            return n.jobCategory?.name === name &&
            isBefore( n.deadline!, new Date());
        }).length;
        return (
            <View style={styles.categoryListItem}>
                <View style={styles.categoryItemHeader}>
                    <Pressable
                        onPress={() => {
                            handleEditCategory({ id: id, name: name }),
                                setShowEditModal(true);
                        }}
                    >
                        <Text style={styles.categoryItemTitle}>
                            <PencilIcon
                                width={18}
                                height={18}
                                style={{ marginRight: 15, marginLeft: 0 }}
                            />
                            {name}
                        </Text>
                    </Pressable>
                    <Text
                        style={styles.categoryDeleteLink}
                        onPress={() => {
                            setCategoryToDelete(id), setShowDeleteModal(true);
                        }}
                    >
                        Delete
                    </Text>
                </View>
                <View style={styles.categoryItemContent}>
                    <Text style={styles.categoryItemData}>
                        {activeJobs} active jobs | {closedJobs} closed jobs
                    </Text>
                </View>
            </View>
        );
    };

    return (
        <ThemedScrollView>
            <DeleteEntityModal
                show={showDeleteModal}
                onCancel={() => setShowDeleteModal(false)}
                entityType="category"
                onConfirm={deleteMutation.mutateAsync}
            />
            <EditJobCategoryModal
                show={showEditModal}
                onClose={() => setShowEditModal(false)}
                control={editControl}
                onSave={submitEditForm}
            />
            <>
                <View style={Theme.pageContent}>
                    <View style={Theme.headerContainer}>
                        <Text style={Theme.headerText}>Manage Job Categories</Text>
                    </View>
                    <View style={styles.newCategoryContainer}>
                        <CtlTextInput
                            control={control}
                            name="name"
                            rules={required}
                            placeholder="Add category..."
                            containerStyle={{ marginBottom: 0, marginRight: 10 }}
                        />
                        <ThemedButton
                            title="Add New"
                            color="black"
                            variant="small"
                            onPress={handleSubmit(submitCreateForm)}
                        />
                    </View>
                    <View style={styles.categoryListContainer}>
                        {jobCategories.length !== 0 && (
                            <FlatList
                                scrollEnabled={false}
                                data={jobCategories}
                                renderItem={({ item }) => (
                                    <Item id={item.id} name={item.name} />
                                )}
                                keyExtractor={(item: JobCategory) => item.id}
                            />
                        )}
                        {jobCategories.length <= 0 && (
                            <Text>No job categories found.</Text>
                        )}
                    </View>
                    <>
                        <Text style={Theme.errMsg}>{errMsg}</Text>
                    </>
                </View>
            </>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    newCategoryContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginVertical: 10,
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    pencilIcon: {
        marginLeft: 10,
    },
    categoryListContainer: {
        width: '100%',
    },
    categoryListItem: {
        height: 75,
        textAlign: 'left',
        borderBottomWidth: 0.75,
        borderBottomColor: Colors.theme.lightGray,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        paddingVertical: 10,
        backgroundColor: Colors.theme.white,
    },
    categoryItemHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    categoryItemTitle: {
        fontSize: 20,
        fontWeight: '500',
    },
    categoryDeleteLink: {
        fontSize: 12,
        color: Colors.theme.orange,
    },
    categoryItemContent: {
        flexDirection: 'row',
        marginTop: 5,
    },
    categoryItemData: {
        fontSize: 12,
        color: Colors.theme.lightGray,
    },
});
