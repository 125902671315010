import { useEffect, useState } from 'react';
import { StyleSheet, View, Text, Image, ScrollView } from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import JobGigStyledText from '../../components/JobGigStyledText';
import { useUser } from '../../hooks';
import { api } from '../../api';
import ThemedButton from '../../components/ThemedButton';
import { ApplicantTabsNavigationProps } from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import AccountProgressBar from '../../components/AccountProgressBar';
import { Applicant, UpdateApplicantDto } from '../../types';
import CtlSwitch from '../../components/ControlledInputs/CtlSwitch';
import { useForm } from 'react-hook-form';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function ApplicantDashboardScreen() {
    type AvailableFormArg = Pick<Applicant, 'id' | 'available'>;

    const [errMsg, setErrMsg] = useState('');
    const { user } = useUser();
    const queryClient = useQueryClient();
    const navigation = useNavigation<ApplicantTabsNavigationProps>();
    const [incompleteSections, setIncompleteSections] = useState<string[]>([]);
    const [applicant, setApplicant] = useState<Applicant>();
    const {
        control,
        watch: watchValues,
        reset,
        getValues,
    } = useForm<AvailableFormArg>(
        {
            defaultValues: {
                available: false,
            },
        }
    );

    useQuery(
        ['profileCompletion', user?.id],
        async () => {
            if (user) return await api.getProfileCompletion(user.id);
        },
        {
            enabled: !!user,
            retry: 1,
            onSuccess: (data) => {
                if (!data) return;
                setIncompleteSections(data);
            },
            onError: (err: Error) => {
                setErrMsg(err.message);
            },
        },
    );

    useQuery(
        ['applicant', user?.id],
        async () => {
            if (user) return await api.getApplicant(user.id);
        },
        {
            enabled: !!user,
            retry: 1,
            onSuccess: (data) => {
                if (!data) return;
                setApplicant(data);
                reset(data);
            },
            onError: (err: Error) => {
                setErrMsg(err.message);
            },
        },
    );

    useEffect(() => {
        if (!getValues()) return;
        setErrMsg('');
        updateMutation.mutateAsync(getValues());
    }, [watchValues('available')]);

    async function updateApplicant(d: UpdateApplicantDto) {
        if (!d.id) return;
        return await api.updateApplicant(d.id, d);
    }

    const updateMutation = useMutation(
        (d: UpdateApplicantDto) => updateApplicant(d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['applicant', user?.id]);
                queryClient.invalidateQueries(['user', user?.id]);
                queryClient.invalidateQueries(['profileCompletion']);
            },
            onError: (err: Error) => {
                setErrMsg('Error updating profile');
            },
        },
    );

    function getHeaderText(): JSX.Element {
        if (incompleteSections && incompleteSections.length === 0) {
            return (
                <Text style={Theme.headerTextCenter}>
                    Welcome back,{' '}
                    <Text style={styles.textOrange}>{user?.firstName}</Text>
                </Text>
            );
        }

        return (
            <Text style={Theme.headerTextCenter}>
                Welcome to <JobGigStyledText size={25} theme="dark" />
            </Text>
        );
    }

    function getAccountProgressStep(): number {
        if (!applicant) return 1;
        if (incompleteSections.length <= 1 && !applicant.accuracyConfirmation)
            return 2;
        if (
            incompleteSections.length <= 1 &&
            applicant.accuracyConfirmation &&
            !applicant.available
        ) return 3;
        if (
            incompleteSections.length === 0 &&
            applicant.accuracyConfirmation &&
            applicant.available
        ) return 4;
        return 1;
    }

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <>
                    <View style={Theme.headerContainerCenter}>
                        <Text style={Theme.headerTextCenter}>
                            {getHeaderText()}
                        </Text>
                    </View>
                    {errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}
                    <View style={styles.getStartedContainer}>
                        {incompleteSections.length > 0 && (
                            <>
                                <Text style={styles.getStartedHeader}>
                                    Ready to get started?
                                </Text>
                                <Text style={styles.getStartedText}>
                                    Complete your applicant profile to start getting
                                    matches.
                                </Text>
                                <ThemedButton
                                    title="Fill out Profile"
                                    color="black"
                                    style={styles.fillOutProfileButton}
                                    onPress={() =>
                                        navigation.navigate('ProfileStack')
                                    }
                                />
                            </>
                        )}
                        {incompleteSections.length == 0 && applicant && (
                            <>
                                {applicant.available ? (
                                    <>
                                        <Text style={styles.getStartedHeader}>
                                            We are finding you a job match.
                                        </Text>
                                        <Image
                                            style={styles.engineerImage}
                                            source={require('../../assets/undraw-engineer.png')}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Image
                                            style={styles.alertImage}
                                            source={require('../../assets/alert-circle.png')}
                                        />
                                        <Text style={styles.getStartedHeader}>
                                            Not Available for Work
                                        </Text>
                                        <Text
                                            style={{
                                                textAlign: 'center',
                                                marginTop: 10,
                                                marginBottom: 20,
                                            }}
                                        >
                                            Change your status to “available”” to be
                                            eligible for job matching
                                        </Text>
                                    </>
                                )}
                                <View style={styles.availContainer}>
                                    <Text style={{ textAlign: 'center' }}>
                                        Be sure to keep your status updated below.
                                    </Text>
                                    <CtlSwitch
                                        name="available"
                                        control={control}
                                        label="Available for work"
                                    />
                                </View>
                            </>
                        )}
                    </View>
                </>
                {!applicant?.available && (
                    <View style={styles.progressBarContainer}>
                        <AccountProgressBar step={getAccountProgressStep()} />
                    </View>
                )}
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    getStartedContainer: {
        maxHeight: 500,
        alignItems: 'center',
        borderRadius: 16,
        marginTop: 10,
        backgroundColor: Colors.theme.white,
        textAlign: 'center',
        padding: 20,
        ...Theme.listItemShadow,
    },
    getStartedHeader: {
        fontSize: 24,
        fontWeight: '700',
        textAlign: 'center',
    },
    getStartedText: {
        fontSize: 16,
        fontWeight: '500',
        marginTop: 10,
    },
    fillOutProfileButton: {
        marginVertical: 50,
    },
    textOrange: {
        color: Colors.theme.orange,
    },
    engineerImage: {
        width: 250,
        height: 250,
        resizeMode: 'contain',
    },
    alertImage: {
        width: 85,
        height: 85,
        resizeMode: 'contain',
        marginBottom: 20,
    },
    progressBarContainer: {
        width: '100%',
        height: 100,
        marginTop: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    availContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 40,
        alignItems: 'center',
    },
});
