import Colors from './Colors';

const PaperTheme = {
    dark: false,
    version: 3,
    roundness: 6,
    colors: {
        primary: Colors.theme.black,
        secondary: Colors.theme.orange,
        background: Colors.theme.backgroundColor,
        accent: Colors.theme.orange,
		tertiary: Colors.theme.orange,
        text: Colors.theme.black,
        disabled: Colors.theme.lightGray,
        placeholder: Colors.theme.black,
        surface: Colors.theme.lightGray,
        onSurface: Colors.theme.black,
        backdrop: Colors.theme.backgroundColor,
        notification: Colors.theme.orange,
        error: Colors.theme.error,
    },
    fonts: {
        fontFamily: 'Roboto',
        thin: {
            fontFamily: 'Roboto',
            fontWeight: '100',
        },
        light: {
            fontFamily: 'Roboto',
            fontWeight: '300',
        },
        regular: {
            fontFamily: 'Roboto',
            fontWeight: 'normal',
        },
        medium: {
            fontFamily: 'Roboto',
            fontWeight: '500',
        },
        bold: {
            fontFamily: 'Roboto',
            fontWeight: '700',
        },
    },
    animation: {
        scale: 1.0,
    },
};

export default PaperTheme;
