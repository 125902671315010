import { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    Text,
    FlatList,
    ScrollView,
    Pressable,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { useUser } from '../../hooks';
import { api } from '../../api';
import {
    AdminDashboardStackProps,
    ApplicantProfileStackProps,
} from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { required } from '../../util/formRules';
import { Applicant } from '../../types';
import { useQuery } from 'react-query';
import { filterSearchResults } from '../../util/helperFunctions';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminManageApplicantsScreen() {
    type SearchArgs = {
        query: string;
    };
    const {
        control,
        handleSubmit,
        reset,
        watch: watchFormValue,
        formState: { isDirty },
    } = useForm<SearchArgs>();
    const [errMsg, setErrMsg] = useState('');
    const { user } = useUser();
    const navigation = useNavigation<AdminDashboardStackProps>();
    const [applicants, setApplicants] = useState<Applicant[]>([]);
    const [filteredApplicants, setFilteredApplicants] = useState<Applicant[]>(
        [],
    );

    useQuery(['applicants', user?.id], () => api.getAllApplicants(), {
        onSuccess: (data) => {
            setApplicants(data);
        },
        onError: (err) => {
            setErrMsg('Error fetching applicants.');
        },
    });

    useEffect(() => {
        setFilteredApplicants(
            filterSearchResults(watchFormValue('query'), applicants, [
                'firstName',
                'lastName',
            ]),
        );
    }, [watchFormValue('query'), applicants]);

    function handleGoToApplicant(applicantId: string) {
        if (!applicantId) return;
        navigation.navigate('ViewApplicant', {
            id: applicantId,
        });
    }

    const Item = ({ applicant }: { applicant: Applicant }) => (
        <Pressable onPress={() => handleGoToApplicant(applicant.id)}>
            <View style={styles.applicantListItem}>
                <Text style={styles.applicantListItemTitle}>
                    {applicant.firstName} {applicant.lastName}
                </Text>
			    {/* <Text
			    	style={StyleSheet.flatten([
			    		styles.profileStatusText,
			    		needsInformation
			    			? styles.profileIncompleteText
			    			: styles.profileCompleteText,
			    	])}
			    >
			    	{needsInformation
			    		? 'Profile Incomplete'
			    		: 'Profile Complete'}
			    </Text> */}
            </View>
        </Pressable>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Applicants</Text>
                    <Text style={Theme.linkText}>Filter & Sort</Text>
                </View>
                <View style={Theme.searchContainer}>
                    <CtlTextInput
                        name="query"
                        control={control}
                        rules={required}
                        placeholder="Search"
                    />
                </View>
                {errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}
                <View style={styles.applicantListContainer}>
                    {filteredApplicants.length !== 0 && (
                        <FlatList
                            scrollEnabled={false}
                            data={filteredApplicants}
                            renderItem={({ item }) => <Item applicant={item} />}
                            keyExtractor={(item: Applicant) => item.id}
                        />
                    )}
                    {filteredApplicants.length <= 0 && (
                        <Text style={styles.textMuted}>
                            No applicants found.
                        </Text>
                    )}
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    sortByLink: {
        fontSize: 20,
        fontWeight: '500',
        textDecorationLine: 'underline',
    },
    applicantListContainer: {
        width: '100%',
    },
    applicantListItem: {
        height: 75,
        backgroundColor: Colors.theme.white,
        borderRadius: 4,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 4,
        textAlign: 'left',
        paddingVertical: 10,
        paddingHorizontal: 20,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        ...Theme.listItemShadow,
    },
    applicantListItemTitle: {
        fontSize: 18,
        fontWeight: '500',
    },
    profileStatusText: {
        fontSize: 14,
        fontWeight: '400',
    },
    profileIncompleteText: {
        color: Colors.theme.error,
    },
    profileCompleteText: {
        color: Colors.theme.text,
    },
    fillOutProfileButton: {
        marginTop: 75,
    },
    textOrange: {
        color: Colors.theme.orange,
    },
    textMuted: {
        color: Colors.theme.lightGray,
    },
    errMsg: {
        color: Colors.theme.error,
    },
});
