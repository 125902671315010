import { useNavigation } from '@react-navigation/native';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    Image,
    StyleProp,
    StyleSheet,
    Text,
    View,
    ViewStyle,
} from 'react-native';
import Colors from '../constants/Colors';
import Theme from '../constants/Theme';
import { PublicStackNavigationProps } from '../navigation/types';
import CtlCheckbox from './ControlledInputs/CtlCheckbox';
import JobGigStyledText from './JobGigStyledText';
import ThemedButton from './ThemedButton';
import Logo from '../assets/Logo';

type OnboardingStep = {
    title: string;
    info: string;
    image: JSX.Element;
    showLogo?: boolean;
};

const Onboarding: React.FC<{
    style?: StyleProp<ViewStyle>;
    onFinish: () => void;
}> = ({ style, onFinish, ...rest }) => {
    const [steps, setSteps] = useState<OnboardingStep[]>([
        {
            title: 'Welcome to',
            info: 'Ready to land your next gig? JobGig can help!',
            image: (
                <Image
                    style={styles.engineerImage}
                    source={require('../assets/undraw-engineer.png')}
                />
            ),
            showLogo: true,
        },
        {
            title: 'Step 1:',
            info: 'Sign up and fill out your profile.',
            image: (
                <Image
                    style={styles.engineerImage}
                    source={require('../assets/onboard-profile.png')}
                />
            ),
        },
        {
            title: 'Step 2:',
            info: 'JobGig will use your profile to match you with jobs.',
            image: (
                <Image
                    style={styles.engineerImage}
                    source={require('../assets/onboard-match-search.png')}
                />
            ),
        },
        {
            title: 'Step 3:',
            info: "Once you're matched, you'll receive a call from one of our recruiters.",
            image: (
                <Image
                    style={styles.engineerImage}
                    source={require('../assets/onboard-match-success.png')}
                />
            ),
        },
        {
            title: 'Ready to get started?',
            info: "Let's get to work.",
            image: (
                <Image
                    style={styles.engineerImage}
                    source={require('../assets/onboard-get-started.png')}
                />
            ),
        },
    ]);
    const [step, setStep] = useState(0);
    const [currentItem, setCurrentItem] = useState<OnboardingStep>(steps[step]);
    const navigation = useNavigation<PublicStackNavigationProps>();
    const { control, watch: watchValues } = useForm<{
        authorizedContact: boolean;
    }>();

    const StepItem = ({ item }: { item: OnboardingStep }) => {
        return (
            <View
                style={{ alignItems: 'center', justifyContent: 'flex-start', flex: 1 }}
            >
                <View style={[Theme.headerContainerCenter, styles.header]}>
                    <Text style={Theme.headerTextCenter}>{item.title}</Text>
                    <View style={{ alignItems: 'center' }}>
                        {item.showLogo && (
                            <Logo width={200} height={125} />
                        )}
                    </View>
                </View>
                <Text style={styles.infoText}>{item.info}</Text>
                <View style={styles.imageContainer}>{item.image}</View>
            </View>
        );
    };

    const ProgressBar = (): JSX.Element => {
        const ProgressDot = ({ active }: { active: boolean }): JSX.Element => {
            return (
                <View
                    style={
                        active
                            ? [styles.progressDot, styles.progressDotActive]
                            : [styles.progressDot]
                    }
                />
            );
        };

        return (
            <View style={styles.progressBar}>
                {steps.map((dot, index) => {
                    return <ProgressDot active={index === step} key={index} />;
                })}
            </View>
        );
    };

    function goNextStep() {
        if (step + 1 < steps.length) {
            setStep(step + 1);
            setCurrentItem(steps[step + 1]);
        } else if (
            step + 1 === steps.length &&
            watchValues('authorizedContact')
        ) {
            onFinish();
        }
    }

    function getButtonText() {
        if (step === steps.length - 1) {
            return 'Get Started';
        }
        return 'Next';
    }

    return (
        <View style={[styles.container, style]} {...rest}>
            <StepItem item={currentItem} />
            <View style={styles.footer}>
                {step === steps.length - 1 && (
                    <View style={styles.checkbox}>
                        <CtlCheckbox
                            label="I authorize JobGig to contact me"
                            name="authorizedContact"
                            control={control}
                        />
                    </View>
                )}
                <ThemedButton
                    title={getButtonText()}
                    color="black"
                    variant="fluid"
                    onPress={goNextStep}
                    disabled={
                        step === steps.length - 1 &&
                        !watchValues('authorizedContact')
                    }
                />
                <ProgressBar />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex-start',
    },
    footer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    engineerImage: {
        marginTop: 50,
        width: 400,
        height: 300,
        resizeMode: 'contain',
    },
    infoText: {
        fontSize: 24,
    },
    imageContainer: {},
    progressBar: {
        width: '100%',
        height: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 20,
    },
    progressDot: {
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: Colors.theme.lightGray,
        marginHorizontal: 5,
    },
    progressDotActive: {
        backgroundColor: Colors.theme.black,
    },
    checkbox: {
        marginLeft: 25,
		marginBottom: 5
    },
});

export default Onboarding;
