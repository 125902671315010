import { Image, TouchableOpacity, Text } from 'react-native';
import Theme from '../constants/Theme';

type SignInWithGoogleButtonProps = {
    onPress: () => void;
};

const SignInWithGoogleButton = (props: SignInWithGoogleButtonProps) => {
    return (
        <TouchableOpacity
            style={[
                Theme.passportMethodButton,
                Theme.passportMethodButtonBorder,
            ]}
            onPress={props.onPress}
        >
            <Image
                style={Theme.passportMethodLogo}
                source={require('../assets/google-icon.png')}
            />
            <Text style={Theme.passportMethodText}>Sign in with Google</Text>
        </TouchableOpacity>
    );
};

export default SignInWithGoogleButton;
